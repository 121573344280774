<template>
  <div>
    <form class="mt-3" @submit.prevent="reset">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <div class="card bg-light">
              <div class="card-body">
                <h3 class="font-weight-light mb-3">Forgot Password</h3>
                <section class="form-group">
                  <div class="col-12 alert alert-danger px-3" v-if="error">
                    {{ error }}
                  </div>
                  <div class="col-12 alert alert-success px-3" v-if="message">
                    {{ message }}
                  </div>
                  <label class="form-control-label sr-only" for="Email"
                    >Email</label
                  >
                  <input
                    class="form-control"
                    v-bind:class="validEmailState()"
                    type="email"
                    id="email"
                    placeholder="Email Address"
                    required
                    v-model="email"
                  />
                  <div class="invalid-feedback">{{ invalidFB.email }}</div>
                </section>
                <section class="form-group">
                  <input
                    class="form-control"
                    v-bind:class="validPinState()"
                    type="password"
                    required
                    placeholder="Pin"
                    v-model="pin"
                  />
                  <div class="invalid-feedback">
                    {{ invalidFB.pin }}
                  </div>
                </section>
                <div class="form-group text-right mb-0">
                  <button class="btn btn-primary" type="submit">
                    Reset Password
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <p class="text-center mt-2">
      <span>
        <router-link to="/login">login</router-link>
        or
        <router-link to="/register">register</router-link>
        or contact
        <a
          href="mailto:admin@olabbio.com?subject=Required Action: Forgot Password"
          >admin</a
        >
        for assistance
      </span>
    </p>
  </div>
</template>
<script>
import { auth, db } from "@/firebase/config.js";

export default {
  data() {
    return {
      email: "",
      pin: "",
      error: "",
      wrongEmail: false,
      wrongPin: false,
      invalidFB: {
        email: "User email not found.",
        pin: "Wrong pin. Try again or contact admin for assistance."
      },
      message: null
    };
  },
  methods: {
    validEmailState() {
      let result = null;
      result = this.wrongEmail ? "is-invalid" : null;
      return result;
    },
    validPinState() {
      let result = null;
      result = this.wrongPin ? "is-invalid" : null;
      return result;
    },
    reset() {
      const info = {
        email: this.email,
        pin: this.pin
      };

      // Check email match with pin
      let count = 0;
      db.collection("db/access/recovery")
        .where("olab_access.visible", "==", "public")
        .where("email", "==", this.email)
        .where("pin", "==", this.pin)
        .get()
        .then((snapShot) => {
          snapShot.forEach((doc) => {
            count++;
            console.log(doc.id, " => ", doc.data());
          });
          console.log("count = ", count);
          if (count < 1) {
            // Email and pin fails to match
            console.log("count < 1, count = ", count);
            this.wrongPin = true;
          } else {
            auth
              .sendPasswordResetEmail(info.email)
              .then(() => {
                console.log("Sent ", info.email, "reset password email");
                this.message = "Password reset request sent.";
              })
              .catch(
                function (error) {
                  if (error.code.toLowerCase().match("user-not-found")) {
                    this.wrongEmail = true;
                    this.invalidFB.email = "User email not found.";
                  } else {
                    this.error = error.code + ": " + error.message;
                  }
                }.bind(this)
              );
          }
        })
        .catch(
          function (error) {
            this.error = "Error Code: " + error.code + " " + error.message;
            setTimeout(() => {
              this.error = null;
            }, 3000);
          }.bind(this)
        );
    }
  },
  watch: {
    email() {
      // console.log("email = " + this.email);
      // Reset
      if (this.wrongEmail) {
        this.wrongEmail = false;
      }
    },
    pin() {
      // console.log("pin = " + this.pin);
      // Reset
      if (this.wrongPin) {
        this.wrongPin = false;
      }
    },
    error() {
      // Reset
      if (this.error) {
        setTimeout(() => {
          this.error = null;
        }, 5000);
      }
    }
  }
};
</script>
