<template>
  <div>
    <header class="brand-color">
      <nav class="family-gugi navbar navbar-expand-md navbar-dark">
        <div class="container-fluid">
          <router-link class="navbar-brand" to="/">OLAB Bioscience</router-link>
          <button
            type="button"
            class="navbar-toggler"
            data-toggle="collapse"
            data-target="#togglerNav"
            aria-controls="#togglerNav"
            aria-label="Toggle Navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="togglerNav">
            <div class="navbar-nav ml-auto">
              <router-link class="nav-item nav-link" to="/blueberry" v-if="user"
                >Blueberry</router-link
              >
              <router-link class="nav-item nav-link" to="/login" v-if="!user"
                >Login</router-link
              >
              <router-link class="nav-item nav-link" to="/register" v-if="!user"
                >Register</router-link
              >
              <div class="text-left">
                <button
                  class="nav-item nav-link btn btn-link"
                  @click="$emit('logout')"
                  v-if="user"
                >
                  Logout
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  </div>
</template>

<script>
export default {
  name: "navigation",
  props: ["user"]
};
</script>

<style lang="scss">
/* nav a.hover, nav a.router-link-active, */
nav a.router-link-exact-active {
  // background-color: #f81;
  font-size: 1.3em;
}
</style>
