<template>
  <div>
    <form class="mt-3" @submit.prevent="register">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <div class="card bg-light">
              <div class="card-body">
                <h3 class="font-weight-light mb-3">Register</h3>
                <div class="form-row">
                  <div
                    v-if="error"
                    class="col-12 alert alert-danger fade show px-3"
                    role="alert"
                  >
                    {{ error }}
                  </div>
                  <section class="col-sm-8 form-group">
                    <label class="form-control-label sr-only" for="displayName"
                      >Name</label
                    >
                    <input
                      class="form-control"
                      type="text"
                      id="displayName"
                      placeholder="Name"
                      name="displayName"
                      required
                      v-model="displayName"
                    />
                  </section>

                  <section class="col-sm-4 form-group">
                    <label class="form-control-label sr-only" for="pin"
                      >Pin</label
                    >
                    <input
                      class="form-control"
                      v-bind:class="validPinState()"
                      type="text"
                      id="pin"
                      placeholder="Pin"
                      required
                      name="pin"
                      v-model="pin"
                    />
                    <div class="invalid-feedback">{{ invalidFB.pin }}</div>
                  </section>
                </div>
                <section class="form-group">
                  <label class="form-control-label sr-only" for="email"
                    >Email</label
                  >
                  <input
                    class="form-control"
                    v-bind:class="validEmailState(email)"
                    type="email"
                    id="email"
                    placeholder="Email Address"
                    required
                    name="email"
                    v-model="email"
                  />
                  <div class="invalid-feedback">{{ invalidFB.email }}</div>
                </section>
                <div class="form-row">
                  <section class="col-sm-6 form-group">
                    <input
                      class="form-control"
                      v-bind:class="validPassOneState()"
                      type="password"
                      required
                      placeholder="Password"
                      v-model="passOne"
                    />
                    <div class="invalid-feedback">{{ invalidFB.passOne }}</div>
                  </section>
                  <section class="col-sm-6 form-group">
                    <input
                      class="form-control"
                      v-bind:class="validPassTwoState()"
                      type="password"
                      required
                      placeholder="Repeat Password"
                      v-model="passTwo"
                    />
                    <div class="invalid-feedback">{{ invalidFB.passTwo }}</div>
                  </section>
                </div>
                <div class="form-group text-right mb-0">
                  <button
                    v-bind:disabled="submitDisabled == true"
                    class="btn btn-primary"
                    type="submit"
                  >
                    Register
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <p class="text-center mt-2">
      or
      <router-link to="/login">login</router-link>
    </p>
  </div>
</template>
<script>
// import { auth, db } from "@/firebase/config.js";
import { auth, db, firebase } from "@/firebase/config.js";
import { OlabUser } from "@/olab/olabuser.js";

export default {
  name: "register",
  data() {
    return {
      displayName: null,
      email: null,
      pin: null,
      passOne: null,
      passTwo: null,
      error: null,
      submitDisabled: false,
      invalidFB: {
        pin: "pin is invalid",
        email: "email format is invalid",
        passOne: "password has to be 6 or more characters",
        passTwo: "passwords must match"
      },
      lastEmail: null,
      lastPin: null,
      checkEmail: false,
      emailTaken: false,
      pinPass: true
    };
  },
  methods: {
    validPinState() {
      // console.log("validPinState: pin = " + this.pin);
      let result = null;
      result = this.pinPass ? null : "is-invalid";
      return result;
    },
    validEmailState(em) {
      // console.log("validEmailState: email = " + em);
      let result = null;
      // Order matter: emailTaken check has to be before checkEmail
      if (this.emailTaken) {
        // console.log("validEmailState: emailTaken = " + this.emailTaken);
        result = "is-invalid";
      } else if (this.checkEmail) {
        result = this.validateEmail(em) ? null : "is-invalid";
      }
      return result;
    },
    validateEmail(em) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(em).toLowerCase());
    },
    validPassOneState() {
      // console.log("validPassOneState: passOne = " + this.passOne);
      let result = null;
      if (this.passOne !== null) {
        if (this.passOne.length < 6) {
          result = "is-invalid";
          this.submitDisabled = this.passTwo !== null ? true : false;
        } else {
          result = "is-valid";
        }
      }
      return result;
    },
    validPassTwoState() {
      // console.log("validPassTwoState: passTwo = " + this.passTwo);
      let result = null;
      if (this.passOne !== null && this.passTwo !== null) {
        if (this.passOne !== this.passTwo) {
          result = "is-invalid";
          this.submitDisabled = true;
        } else {
          result = "is-valid";
          this.submitDisabled = this.passOne.length < 6 ? true : false;
        }
      }
      return result;
    },
    async register() {
      this.checkEmail = true;

      // Check to make sure email is of correct format
      if (!this.validateEmail(this.email)) {
        this.passOne = null;
        this.passTwo = null;
      } else {
        console.log("Register user ....");
        // Check to make sure pin matches email before registation
        let count = 0;
        db.collection("db/access/recovery")
          .where("olab_access.visible", "==", "public")
          .where("email", "==", this.email)
          .where("pin", "==", this.pin)
          .get()
          .then((snapShot) => {
            snapShot.forEach((doc) => {
              count++;
              console.log(doc.id, " => ", doc.data());
            });
            console.log("count = " + count);
            if (count < 1) {
              // Pin fails to match
              console.log("count < 1, count = " + count);
              this.pinPass = false;
              this.passOne = null;
              this.passTwo = null;
            } else {
              const info = new OlabUser();
              info.email = this.email;
              info.display_name = this.displayName;
              info.password = this.passOne;
              console.log("info user =", info);

              auth
                .createUserWithEmailAndPassword(info.email, info.password)
                .then((userCredentials) => {
                  console.log("user = " + userCredentials.user);
                  console.log("user uid = " + userCredentials.user.uid);

                  // Uncomment this code segment when adding new users (For Admin)
                  // ********
                  info.auth_uid = userCredentials.user.uid;
                  info.olab_access.roles = {
                    // "owner", "writer", "viewer"
                    [info.email]: "owner"
                  };
                  OlabUser.infoLog(info);

                  // Create user in the test/olab_users collection
                  // cjcj users will use cjcj/olab_users collection
                  // and olab users will use olab/olab_users collection
                  // console.log("Register: users/test/olab_users");
                  db.collection("users/test/olab_users")
                    .withConverter(OlabUser.getCreateOlabUserConverter())
                    .doc(info.email)
                    .set(info);
                  // *********

                  auth
                    .setPersistence(firebase.auth.Auth.Persistence.SESSION)
                    .then(() => {
                      console.log(
                        "Register: setPersistence(firebase.auth.Auth.Persistence.SESSION)"
                      );
                      // Existing and future Auth states are now persisted
                      // in the current session only. Closing the window
                      // would clear any existing state even if a user forgets
                      // to sign out.
                      // ...
                      // New sign-in will be persisted with session persistence.
                      auth.signInWithEmailAndPassword(
                        info.email,
                        info.password
                      );

                      console.log("Return to home ...");
                      // Return to home
                      this.$router.replace("/");
                      return;
                    })
                    .catch(
                      function (error) {
                        // Handle Errors here.
                        this.error =
                          "Error Code OLAB 0: " +
                          error.code +
                          " " +
                          error.message;
                        setTimeout(() => {
                          this.error = null;
                          this.passOne = null;
                          this.passTwo = null;
                        }, 3000);
                      }.bind(this)
                    );
                })
                .catch(
                  function (error) {
                    console.log("** error =", error);
                    if (error.code.toLowerCase().match("email-already-")) {
                      this.emailTaken = true;
                      this.invalidFB.email = "email has been taken";
                      this.passOne = null;
                      this.passTwo = null;
                    } else {
                      this.error =
                        "Error Code OLAB 1: " +
                        error.code +
                        " " +
                        error.message;
                      setTimeout(() => {
                        this.error = null;
                        this.passOne = null;
                        this.passTwo = null;
                      }, 3000);
                    }
                  }.bind(this)
                );
            }
          })
          .catch(
            function (error) {
              this.error =
                "Error Code OLAB 2: " + error.code + " " + error.message;
              setTimeout(() => {
                this.error = null;
                this.passOne = null;
                this.passTwo = null;
              }, 3000);
            }.bind(this)
          );
      }
    }
  },
  watch: {
    pin() {
      // console.log("pin = " + this.email);
      if (this.pin !== this.lastPin) {
        this.pinPass = true;
      }
      this.lastPin = this.pin;
    },
    email() {
      // console.log("email = " + this.email);
      if (this.email !== this.lastEmail) {
        if (this.emailTaken) {
          this.emailTaken = false;
          // Restore email message
          this.invalidFB.email = "email format is invalid";
        } else if (this.checkEmail) {
          this.checkEmail = false;
        }
      }
      this.lastEmail = this.email;
    },
    passOne() {
      // console.log("passOne = " + this.passOne);
    }
  }
};
</script>
