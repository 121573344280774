export const recipes = [
  {
    olab_id: "RCP-0001",
    name: "LB liquid medium",
    volume: 1000,
    units: "mL",
    ingredients: [
      {
        qty: 10,
        unit: "g",
        substance: "Tryptone"
      },
      {
        qty: 10,
        unit: "g",
        substance: "NaCl"
      },
      {
        qty: 5,
        unit: "g",
        substance: "Yeast extract"
      }
    ]
  },
  {
    olab_id: "RCP-0002",
    name: "LB agar (2%) medium",
    volume: 1000,
    units: "mL",
    ingredients: [
      {
        qty: 10,
        unit: "g",
        substance: "Tryptone"
      },
      {
        qty: 10,
        unit: "g",
        substance: "NaCl"
      },
      {
        qty: 5,
        unit: "g",
        substance: "Yeast extract"
      },
      {
        qty: 20,
        unit: "g",
        substance: "Agar"
      }
    ]
  },
  {
    olab_id: "RCP-0003",
    name: "YPD medium",
    volume: 1000,
    units: "mL",
    ingredients: [
      {
        qty: 20,
        unit: "g",
        substance: "Bacto peptone"
      },
      {
        qty: 10,
        unit: "g",
        substance: "Yeast extract"
      }
    ]
  },
  {
    olab_id: "RCP-0004",
    name: "YPD agar medium",
    volume: 1000,
    units: "mL",
    ingredients: [
      {
        qty: 24,
        unit: "g",
        substance: "Bacto agar"
      },
      {
        qty: 20,
        unit: "g",
        substance: "Bacto peptone"
      },
      {
        qty: 10,
        unit: "g",
        substance: "Yeast extract"
      }
    ]
  },
  {
    olab_id: "RCP-0005",
    name: "CM agar medium",
    volume: "1000",
    units: "mL",
    ingredients: [
      {
        qty: 6.7,
        unit: "g",
        substance: "YNB"
      },
      {
        qty: 20,
        unit: "g",
        substance: "Glucose"
      },
      {
        qty: 20,
        unit: "g",
        substance: "Bacto agar"
      },
      {
        qty: 2,
        unit: "g",
        substance: "Drop-out mix"
      }
    ]
  },
  {
    olab_id: "RCP-0006",
    name: "SD liquid medium",
    volume: "1000",
    units: "mL",
    ingredients: [
      {
        qty: 1.7,
        unit: "g",
        substance:
          "Yeast nitrogen base (without amino acids and nitrogen substrate)"
      },
      {
        qty: 5,
        unit: "g",
        substance: "Ammonium Sulfate"
      },
      {
        qty: 20,
        unit: "g",
        substance: "Dextrose"
      },
      {
        qty: 1,
        unit: "unit",
        substance: "CSM (* varies)"
      },
      {
        qty: 20,
        unit: "g",
        substance: "Agar"
      }
    ]
  },
  {
    olab_id: "RCP-0007",
    name: "C.glutamicum liquid medium",
    volume: "1000",
    units: "mL",
    ingredients: [
      {
        qty: 10,
        unit: "g",
        substance: "Tryptone"
      },
      {
        qty: 10,
        unit: "g",
        substance: "NaCl"
      },
      {
        qty: 5,
        unit: "g",
        substance: "Yeast extract"
      },
      {
        qty: 5,
        unit: "g",
        substance: "Glucose"
      },
      {
        qty: 25,
        unit: "g",
        substance: "Glycine"
      },
      {
        qty: 4,
        unit: "g",
        substance: "isonicotinic acid hydrazid"
      }
    ]
  },
  {
    olab_id: "RCP-0008",
    name: "4X SDS sample buffer for Western Blotting",
    volume: "7.5",
    units: "mL",
    ingredients: [
      {
        qty: 1.5,
        unit: "mL",
        substance: "1 M Tris-HCl pH 6.5"
      },
      {
        qty: 3,
        unit: "mL",
        substance: "1 M DTT (dithiothreitol)"
      },
      {
        qty: 0.6,
        unit: "g",
        substance: "SDS (sodium dodecyl sulfate)"
      },
      {
        qty: 30,
        unit: "mg",
        substance: "bromophenol blue"
      },
      {
        qty: 2.4,
        unit: "mL",
        substance: "glycerol"
      }
    ]
  }
];
