<template>
  <div>
    <article id="page-multicolumn" class="page-section text-center py-5">
      <header class="page-section-header container">
        <div class="row justify-content-center">
          <div class="col-11 col-sm-10 col-md-8">
            <h2 class="page-section-title">Blueberry</h2>
            <p class="page-section-text">
              This page contains Mockup forms for implementing the Blueberry
              Infomatics.
            </p>
            <div>
              <span class="mx-2">
                <font-awesome-icon
                  class="text-success"
                  icon="square"
                ></font-awesome-icon
                ><span> Done</span>
              </span>
              <span class="mx-2">
                <font-awesome-icon
                  class="text-warning"
                  icon="square"
                ></font-awesome-icon
                ><span> In progress</span>
              </span>
              <span class="mx-2">
                <font-awesome-icon
                  class="text-danger"
                  icon="square"
                ></font-awesome-icon
                ><span> To-do</span>
              </span>
            </div>
          </div>
        </div>
      </header>

      <section class="layout-multicolumn container">
        <div class="row justify-content-center family-sans text-uppercase">
          <template v-for="bForm in blueberryForms" v-bind:key="bForm.name">
            <section class="mb-5 col-10 col-md-6 col-lg-4 col-xl-3">
              <a v-bind:href="bForm.url">
                <OlabFormSVGGen
                  v-bind:name="bForm.name"
                  v-bind:textPosX="bForm.textPosX"
                  v-bind:textPosY="bForm.textPosY"
                  v-bind:status="bForm.status"
                  v-bind:editStatus="bForm.editStatus"
                  v-bind:viewStatus="bForm.viewStatus"
                />
              </a>
            </section>
          </template>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
// @ is an alias to /src
import OlabFormSVGGen from "@/components/OlabFormSVGGen";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "blueberry",
  components: {
    OlabFormSVGGen,
    FontAwesomeIcon
  },
  data() {
    return {
      blueberryForms: [
        {
          name: "Chemical",
          url: "olabchemical",
          textPosX: "210",
          textPosY: "400",
          status: "done",
          editStatus: "todo",
          viewStatus: "todo"
        },
        {
          name: "Flask",
          url: "olabflask",
          textPosX: "255",
          textPosY: "400",
          status: "done",
          editStatus: "todo",
          viewStatus: "todo"
        },
        {
          name: "Incubator",
          url: "olabincubator",
          textPosX: "195",
          textPosY: "400",
          status: "done",
          editStatus: "todo",
          viewStatus: "todo"
        },
        {
          name: "Manufacture",
          url: "olabmanufacture",
          textPosX: "155",
          textPosY: "400",
          status: "done",
          editStatus: "todo",
          viewStatus: "todo"
        },
        {
          name: "Media",
          url: "olabmedia",
          textPosX: "250",
          textPosY: "400",
          status: "done",
          editStatus: "todo",
          viewStatus: "todo"
        },
        {
          name: "Recipe",
          url: "olabrecipe",
          textPosX: "230",
          textPosY: "400",
          status: "done",
          editStatus: "todo",
          viewStatus: "todo"
        },
        {
          name: "Plate",
          url: "olabplate",
          textPosX: "240",
          textPosY: "400",
          status: "done",
          editStatus: "todo",
          viewStatus: "todo"
        },
        {
          name: "Seed",
          url: "olabseed",
          textPosX: "255",
          textPosY: "400",
          status: "done",
          editStatus: "todo",
          viewStatus: "todo"
        },
        {
          name: "User",
          url: "olabuser",
          textPosX: "250",
          textPosY: "400",
          status: "done",
          editStatus: "done",
          viewStatus: "done"
        }
      ]
    };
  }
};
</script>
