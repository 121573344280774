<template>
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <g transform="translate(150,150)">
        <path
          fill="#eeeeee"
          transform="scale(0.5)"
          d="M448 75.2v361.7c0 24.3-19 43.2-43.2 43.2H43.2C19.3 480 0 461.4 0 436.8V75.2C0 51.1 18.8 32 43.2 32h361.7c24 0 43.1 18.8 43.1 43.2zm-37.3 361.6V75.2c0-3-2.6-5.8-5.8-5.8h-9.3L285.3 144 224 94.1 162.8 144 52.5 69.3h-9.3c-3.2 0-5.8 2.8-5.8 5.8v361.7c0 3 2.6 5.8 5.8 5.8h361.7c3.2.1 5.8-2.7 5.8-5.8zM150.2 186v37H76.7v-37h73.5zm0 74.4v37.3H76.7v-37.3h73.5zm11.1-147.3l54-43.7H96.8l64.5 43.7zm210 72.9v37h-196v-37h196zm0 74.4v37.3h-196v-37.3h196zm-84.6-147.3l64.5-43.7H232.8l53.9 43.7zM371.3 335v37.3h-99.4V335h99.4z"
        />
      </g>
      <text
        :x="textPosX"
        :y="textPosY"
        fill="#f80"
        transform="rotate(-47,175,400)"
        style="
          font-size: 45px;
          font-weight: normal;
          text-align: start;
          text-anchor: start;
          fill-opacity: 1;
          stroke: none;
          stroke-width: 1px;
          stroke-linecap: butt;
          stroke-linejoin: miter;
          stroke-opacity: 1;
          font-family: Arial;
        "
        id="olab-text"
        xml:space="preserve"
      >
        {{ name }}
      </text>
      <!-- Status info -->
      <!-- Add status  -->
      <text x="210" y="200" style="font-size: 20px" fill="blue">Add</text>
      <rect
        v-if="status === 'done'"
        x="180"
        y="180"
        width="20"
        height="20"
        fill="#5cb85c"
      />
      <rect
        v-else-if="status === 'inprogress'"
        x="180"
        y="180"
        width="20"
        height="20"
        fill="#f0ad4e"
      />
      <rect v-else x="180" y="180" width="20" height="20" fill="#d9534f" />

      <!-- Edit status  -->
      <text x="210" y="230" style="font-size: 20px" fill="blue">Edit</text>
      <rect
        v-if="editStatus === 'done'"
        x="180"
        y="210"
        width="20"
        height="20"
        fill="#5cb85c"
      />
      <rect
        v-else-if="editStatus === 'inprogress'"
        x="180"
        y="210"
        width="20"
        height="20"
        fill="#f0ad4e"
      />
      <rect v-else x="180" y="210" width="20" height="20" fill="#d9534f" />

      <!-- View status  -->
      <text x="210" y="260" style="font-size: 20px" fill="blue">View</text>
      <rect
        v-if="viewStatus === 'done'"
        x="180"
        y="240"
        width="20"
        height="20"
        fill="#5cb85c"
      />
      <rect
        v-else-if="viewStatus === 'inprogress'"
        x="180"
        y="240"
        width="20"
        height="20"
        fill="#f0ad4e"
      />
      <rect v-else x="180" y="240" width="20" height="20" fill="#d9534f" />
    </svg>
  </div>
</template>

<script>
export default {
  name: "olabformsvggen",
  props: ["name", "textPosX", "textPosY", "status", "editStatus", "viewStatus"]
};
</script>
