<template>
  <div class="chemicalface">
    <div class="container text-left mt-3">
      <FormSelector :selectedTypeName="selectedTypeName" />
      <ChemicalAddForm
        :statusObj="statusObj"
        :errorObj="errorObj"
        @emitStatusMessage="emitStatusMessage"
        @emitErrorMessage="emitErrorMessage"
      />
      <ChemicalEditForm
        :statusObj="statusObj"
        :errorObj="errorObj"
        @emitStatusMessage="emitStatusMessage"
        @emitErrorMessage="emitErrorMessage"
      />
      <ChemicalViewForm
        :statusObj="statusObj"
        :errorObj="errorObj"
        @emitStatusMessage="emitStatusMessage"
        @emitErrorMessage="emitErrorMessage"
      />
    </div>
    <div class="container">
      <div class="row justify-content-center">
        <div
          v-if="errorObj.message"
          class="col-12 alert alert-danger text-center px-3"
        >
          {{ errorObj.message }}
          <div class="text-center mt-2">
            <button
              type="button"
              class="btn btn-danger"
              v-on:click="closeError()"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="container text-left">
      <p>TODO: Implement capture result display here</p>
    </div>
  </div>
</template>

<script>
import FormSelector from "@/components/blueberry/FormSelector";
import ChemicalAddForm from "@/components/blueberry/ChemicalAddForm";
import ChemicalEditForm from "@/components/blueberry/ChemicalEditForm";
import ChemicalViewForm from "@/components/blueberry/ChemicalViewForm";
import { chemicals } from "@/assets/data/blueberry/chemicals.js";

export default {
  name: "olabchemical",
  data() {
    return {
      selectedType: "chemical",
      selectedTypeName: "Chemical",
      chemicals
    };
  },
  components: {
    FormSelector,
    ChemicalAddForm,
    ChemicalEditForm,
    ChemicalViewForm
  },
  props: ["statusObj", "errorObj"],
  emits: ["setStatusMessage", "setErrorMessage"],
  mounted() {
    // console.log("chemical mounted ...");
    // console.log("chemicals =", this.chemicals);
    // Reset status and error
    this.emitStatusMessage(null, false);
    this.emitErrorMessage(null);
  },
  methods: {
    emitStatusMessage(statusMsg, statusProgress) {
      this.$emit("setStatusMessage", statusMsg, statusProgress);
    },
    emitErrorMessage(errMsg) {
      this.$emit("setErrorMessage", errMsg);
    },
    closeStatus() {
      // console.log("Close Status Alert ...");
      this.emitStatusMessage(null, false);
    },
    closeError() {
      // console.log("Close Error Alert ...");
      this.emitErrorMessage(null);
    }
  }
};
</script>

<style scoped>
.chemicalface {
  padding: 50px 0;
  background-color: #fdf2e9;
}
</style>
