export const manufacturers = [
  {
    olab_id: "MFR-0001",
    name: "Angel",
    address_1: "Address 1",
    address_2: "Address 2",
    city: "City",
    state: "State",
    zip: "Zip",
    country: "Country",
    contact_person: "Contact Person",
    email: "Email",
    phone: "Phone",
    note: "Note"
  },
  {
    olab_id: "MFR-0002",
    name: "ROQUETTE",
    address_1: "Address 1",
    address_2: "Address 2",
    city: "City",
    state: "State",
    zip: "Zip",
    country: "Country",
    contact_person: "Contact Person",
    email: "Email",
    phone: "Phone",
    note: "Note"
  }
];
