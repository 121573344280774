<template>
  <div>
    <div class="modal fade" id="addItem">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Add:
              <span class="text-dark text-truncate">
                {{ selectedTypeName }}
              </span>
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <!-- .modal-header -->
          <div class="modal-body">
            <form v-on:submit.prevent="onSubmit">
              <div class="form-group">
                <label for="inputCompanyName">Company</label>
                <input
                  type="text"
                  class="form-control"
                  v-bind:class="item.getValidState(item.name)"
                  v-model="item.name"
                  id="inputCompanyName"
                  placeholder="Company Name"
                />
              </div>
              <div class="form-group">
                <label for="inputAddress">Address</label>
                <input
                  type="text"
                  class="form-control"
                  v-bind:class="item.getValidState(item.addr1)"
                  v-model="item.addr1"
                  id="inputAddress"
                  placeholder="1234 Main St"
                />
              </div>
              <div class="form-group">
                <label for="inputAddress2">Address 2</label>
                <input
                  type="text"
                  class="form-control"
                  v-bind:class="item.getValidState(item.addr2)"
                  v-model="item.addr2"
                  id="inputAddress2"
                />
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="inputCity">City</label>
                  <input
                    type="text"
                    class="form-control"
                    v-bind:class="item.getValidState(item.city)"
                    v-model="item.city"
                    id="inputCity"
                  />
                </div>
                <div class="form-group col-md-4">
                  <label for="inputState">State</label>
                  <select
                    id="inputState"
                    class="form-control"
                    v-bind:class="item.getValidState(item.state)"
                    v-model="item.state"
                  >
                    <option disabled>Choose...</option>
                    <option value="NA">Not in US</option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </select>
                </div>
                <div class="form-group col-md-2">
                  <label for="inputZip">Zip</label>
                  <input
                    type="text"
                    class="form-control"
                    v-bind:class="item.getValidState(item.zipcode)"
                    v-model="item.zipcode"
                    id="inputZip"
                  />
                </div>
              </div>
              <div class="form-group">
                <label for="inputCountry">Country</label>
                <input
                  type="text"
                  class="form-control"
                  v-bind:class="item.getValidState(item.country)"
                  v-model="item.country"
                  id="inputCountry"
                />
              </div>
              <hr />
              <div class="form-group">
                <label for="inputName">Contact Person</label>
                <input
                  type="text"
                  class="form-control"
                  v-bind:class="item.getValidState(item.contact)"
                  v-model="item.contact"
                  id="inputName"
                  placeholder="Name"
                />
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="inputEmail">Email</label>
                  <input
                    type="text"
                    class="form-control"
                    v-bind:class="validEmailState(item.email)"
                    v-model="item.email"
                    id="inputEmail"
                    placeholder="name@example.com"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label for="inputPhone">Phone</label>
                  <input
                    type="tel-country-code"
                    class="form-control"
                    v-bind:class="item.getValidState(item.phone)"
                    v-model="item.phone"
                    id="inputPhone"
                  />
                </div>
              </div>
              <div class="form-group">
                <label for="inputNote">Note</label>
                <textarea
                  type="textarea"
                  class="form-control"
                  v-model="item.note"
                  id="inputNote"
                ></textarea>
              </div>
            </form>
          </div>
          <!-- .modal-body -->

          <div class="modal-footer">
            <button
              type="button"
              v-bind:disabled="itemAddIsDisabled == true"
              class="btn btn-primary"
              v-on:click="handleAddItem(item)"
            >
              <span
                v-if="statusObj.progress"
                class="spinner-border spinner-border-sm text-light"
              ></span>
              Add
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancel
            </button>
          </div>
          <!-- .modal-footer -->
        </div>
        <!-- .modal-content -->
      </div>
      <!-- .modal-dialog .modal-lg-->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import { OlabUtils } from "@/olab/olabutils.js";
import { OlabItem } from "@/olab/olabitem.js";

export default {
  name: "manufactureaddform",
  data() {
    return {
      selectedType: "manufacture",
      selectedTypeName: "Manufacture",
      item: new OlabItem(),
      invalidFB: OlabItem.getInvalidFeedbacks()
    };
  },
  props: ["statusObj", "errorObj"],
  emits: ["emitStatusMessage", "emitErrorMessage"],
  mounted() {
    console.log("ManufactureAddForm mounted ...");
    // Reset status and error
    this.doEmitStatusMessage(null, false);
    this.doEmitErrorMessage(null);
  },
  computed: {
    itemAddIsDisabled() {
      return !this.item.isItemValid(this.selectedType, true);
    }
  },
  methods: {
    handleAddItem(item) {
      item.olab_type = this.selectedType;
      console.log("TODO: handleAddItem =", item);
    },
    validEmailState(em) {
      console.log("validEmailState: email = " + em);
      return OlabUtils.validateEmail(em) ? "is-valid" : "is-invalid";
    },
    doEmitStatusMessage(statusMsg, statusProgress) {
      this.$emit("emitStatusMessage", statusMsg, statusProgress);
    },
    doEmitErrorMessage(errMsg) {
      this.$emit("emitErrorMessage", errMsg);
    },
    closeStatus() {
      // console.log("Close Status Alert ...");
      this.emitStatusMessage(null, false);
    },
    closeError() {
      // console.log("Close Error Alert ...");
      this.emitErrorMessage(null);
    },
    onSubmit() {
      // This is a NOP to prevent page reload on return key
      console.log("After Submit");
    }
  }
};
</script>
