import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./firebase/config";
import "bootstrap";

import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faTrash,
  faTrashAlt,
  faLink,
  faListUl,
  faRandom,
  faUndo,
  faStar,
  faEnvelope,
  faEye,
  faDna,
  faSeedling,
  faPlus,
  faPlusSquare,
  faHandPointer,
  faIdBadge,
  faBlog,
  faAddressBook,
  faQuestion,
  faQuestionCircle,
  faLock,
  faLockOpen,
  faPen,
  faSquare,
  faTimes,
  faChevronUp,
  faChevronDown
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faTrash,
  faTrashAlt,
  faLink,
  faListUl,
  faRandom,
  faUndo,
  faStar,
  faPlus,
  faPlusSquare,
  faEnvelope,
  faEye,
  faDna,
  faSeedling,
  faHandPointer,
  faIdBadge,
  faBlog,
  faAddressBook,
  faQuestion,
  faQuestionCircle,
  faLock,
  faLockOpen,
  faPen,
  faSquare,
  faTimes,
  faChevronUp,
  faChevronDown
);

const app = createApp(App);

app.use(store);
app.use(router);

app.mount("#app");
