<template>
  <div>
    <div class="modal fade" id="viewItem">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              View:
              <span class="text-dark text-truncate">
                {{ selectedTypeName }}
              </span>
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <!-- .modal-header -->
          <div class="modal-body">
            <div class="py-3 text-wrap">
              <span class="mr-1 h5">OLAB ID:</span>
              <span class="ml-1 h5">{{ item.olab_id }}</span>
            </div>
            <div class="form-row">
              <div class="form-group col-md-4">
                <span class="mr-1">Name:</span>
                <span class="ml-1">{{ item.name }}</span>
              </div>
              <div class="form-group col-md-4">
                <span class="mr-1">Type:</span>
                <span class="ml-1">{{ item.type }}</span>
              </div>
              <div class="form-group col-md-4">
                <span class="mr-1">Temperator:</span>
                <span class="ml-1">{{ item.temperature }} &#8451;</span>
              </div>
            </div>
            <div class="form-group">
              <span class="mr-1">Description:</span>
              <span class="ml-1">{{ item.desc }}</span>
            </div>
            <div class="form-group">
              <span class="mr-1">Note:</span>
              <span class="ml-1">{{ item.note }}</span>
            </div>
          </div>
          <!-- .modal-body -->

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancel
            </button>
          </div>
          <!-- .modal-footer -->
        </div>
        <!-- .modal-content -->
      </div>
      <!-- .modal-dialog .modal-lg-->
    </div>
  </div>
</template>

<script>
export default {
  name: "incubatorviewform",
  data() {
    return {
      selectedType: "incubator",
      selectedTypeName: "Incubator",
      item: {
        olab_id: "ICB-000001",
        name: "Stationary #1",
        type: "Stationary",
        temperature: 37.0,
        // C for Celsius or F or Fahrenheit
        temperature_unit: "C",
        desc: "description section",
        note: "Note section"
      }
    };
  },
  props: ["statusObj", "errorObj"],
  emits: ["emitStatusMessage", "emitErrorMessage"],
  mounted() {
    console.log("IncubatorViewForm mounted ...");
    // Reset status and error
    this.doEmitStatusMessage(null, false);
    this.doEmitErrorMessage(null);
  },
  methods: {
    doEmitStatusMessage(statusMsg, statusProgress) {
      this.$emit("emitStatusMessage", statusMsg, statusProgress);
    },
    doEmitErrorMessage(errMsg) {
      this.$emit("emitErrorMessage", errMsg);
    },
    closeStatus() {
      // console.log("Close Status Alert ...");
      this.emitStatusMessage(null, false);
    },
    closeError() {
      // console.log("Close Error Alert ...");
      this.emitErrorMessage(null);
    }
  }
};
</script>
