// Import the functions you need from the SDKs you need
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBH5fn2HxJhmTwkvgzxgvIADCkP0p91TQY",
  authDomain: "gf-olab-demo.firebaseapp.com",
  projectId: "gf-olab-demo",
  storageBucket: "gf-olab-demo.appspot.com",
  messagingSenderId: "870046727099",
  appId: "1:870046727099:web:0d8b3bbfac774a8e5c629e",
  measurementId: "G-BG6Z5938P4"
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);
const auth = firebaseApp.auth();
const db = firebaseApp.firestore();

// firebase.analytics();

export { auth, db, firebase };
