<template>
  <div class="modal fade" id="addItem">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Add:
            <span class="text-dark text-truncate">
              {{ selectedTypeName }}
            </span>
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <!-- .modal-header -->
        <div class="modal-body">
          <!-- Recipe formula  -->
          <div class="col-lg-12">
            <div class="card">
              <div class="card-header">
                <form v-on:submit.prevent="onSubmit">
                  <div class="form-row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="inputRecipeName">Recipe Name</label>
                        <input
                          name="Recipe Name"
                          type="text"
                          placeholder="Recipe Name"
                          class="mx-2 form-control text-primary"
                          v-bind:class="item.getValidState(item.name)"
                          style="width: 180px; text-align: left"
                          v-model="item.name"
                          v-bind:disabled="watchField !== 'recipeFormula'"
                        />
                      </div>
                    </div>
                    <!--  -->
                    <!--   -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="inputVolume">Volume</label>
                        <div class="form-inline">
                          <input
                            name="Volume"
                            type="number"
                            min="0"
                            placeholder="Vol"
                            class="mx-2 form-control text-primary"
                            v-bind:class="item.isGreaterThanZero(item.volume)"
                            style="width: 120px; text-align: left"
                            v-model="item.volume"
                            v-bind:disabled="watchField !== 'recipeFormula'"
                            @keyup="
                              item.volume = clampPostiveValue(item.volume)
                            "
                          />
                          <select
                            class="form-control text-light bg-secondary"
                            v-model="item.units"
                          >
                            <option>mL</option>
                            <option>L</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="card-body">
                <h4>Ingredients</h4>
                <form
                  class="form-inline"
                  v-for="(ingredient, k) in item.ingredients"
                  v-bind:key="k"
                  v-on:submit.prevent="onSubmit"
                >
                  <button class="btn" v-if="watchField === 'recipeFormula'">
                    <font-awesome-icon
                      class="text-warning"
                      icon="trash-alt"
                      v-on:click="deleteRow(k)"
                    >
                      &nbsp;
                    </font-awesome-icon>
                  </button>
                  <input
                    type="number"
                    min="0"
                    placeholder="Qty"
                    class="form-control text-primary"
                    v-bind:class="item.isGreaterThanZero(item.ingredients[k].qty)"
                    style="width: 120px; text-align: center"
                    v-model="ingredient.qty"
                    @keyup="ingredient.qty = clampPostiveValue(ingredient.qty)"
                  />
                  <select
                    class="form-control mx-2 text-light bg-secondary"
                    v-model="ingredient.unit"
                    v-bind:disabled="watchField !== 'recipeFormula'"
                  >
                    <option>mL</option>
                    <option>L</option>
                    <option>mg</option>
                    <option>g</option>
                  </select>
                  <input
                    type="text"
                    placeholder="Substance"
                    class="form-control text-primary"
                    v-bind:class="
                      item.getValidState(item.ingredients[k].substance)
                    "
                    v-model="ingredient.substance"
                  />
                </form>
                <button class="btn" v-if="watchField === 'recipeFormula'">
                  <font-awesome-icon
                    class="mr-1 text-warning"
                    icon="plus-square"
                    v-on:click="addNewRow"
                  >
                    &nbsp;
                  </font-awesome-icon>
                </button>
                <div class="pb-3">
                  <span>Adjust final volume to</span>
                  <span class="h5 text-primary bg-light mx-1"
                    >{{ item.volume }} {{ item.units }}</span
                  >
                  <span>with Milli-Q Water</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- .modal-body -->

        <div class="modal-footer">
          <button
            type="button"
            v-bind:disabled="itemAddIsDisabled == true"
            class="btn btn-primary"
            v-on:click="handleAddItem(item)"
          >
            <span
              v-if="statusObj.progress"
              class="spinner-border spinner-border-sm text-light"
            ></span>
            Add
          </button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Cancel
          </button>
        </div>
        <!-- .modal-footer -->
      </div>
      <!-- .modal-content -->
    </div>
    <!-- .modal-dialog .modal-lg-->
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { OlabItem } from "@/olab/olabitem.js";
// import { OlabUtils } from "@/olab/olabutils";

export default {
  name: "recipeaddform",
  data() {
    return {
      selectedType: "recipe",
      selectedTypeName: "Recipe",
      item: new OlabItem(),
      units: "mL",
      invalidFB: OlabItem.getInvalidFeedbacks(),
      recipeIndex: 0,
      // watchField store the field that any variable that depend on it will be recompute.
      watchField: "recipeFormula"
    };
  },
  components: {
    FontAwesomeIcon
  },
  props: ["statusObj", "errorObj"],
  emits: ["emitStatusMessage", "emitErrorMessage"],
  mounted() {
    console.log("RecipeAddForm mounted ...");
    // Reset status and error
    this.doEmitStatusMessage(null, false);
    this.doEmitErrorMessage(null);
    console.log("item =", this.item);
    // initialise item.ingredients array
    const ingArr = [
      {
        qty: 0,
        unit: "g",
        substance: ""
      }
    ];
    this.item.ingredients = ingArr;
  },
  computed: {
    itemAddIsDisabled() {
      return !this.item.isItemValid(this.selectedType, true);
    }
  },
  methods: {
    handleAddItem(item) {
      console.log("TODO: handleAddItem =", item);
    },
    doEmitStatusMessage(statusMsg, statusProgress) {
      this.$emit("emitStatusMessage", statusMsg, statusProgress);
    },
    doEmitErrorMessage(errMsg) {
      this.$emit("emitErrorMessage", errMsg);
    },
    closeStatus() {
      // console.log("Close Status Alert ...");
      this.emitStatusMessage(null, false);
    },
    closeError() {
      // console.log("Close Error Alert ...");
      this.emitErrorMessage(null);
    },
    onSubmit() {
      // This is a NOP to prevent page reload on return key
      console.log("After Submit");
    },
    clampPostiveValue(value) {
      if (!isNaN(value)) {
        value = value < 0 ? 0 : value;
        return value;
      }
      return 0;
    },
    // The addNewRow and deleteRow is to add or delete ingredient in the recipes formula
    addNewRow() {
      this.item.ingredients.push({
        qty: "",
        unit: "g",
        substance: ""
      });
      console.log("add new row");
    },
    deleteRow(index) {
      var idx = index;
      console.log(idx, index);
      if (idx > -1) {
        console.log("recipe index number", index);
        this.item.ingredients.splice(idx, 1);
      }
    }
  }
};
</script>
