import { OlabUtils } from "./olabutils.js";

class OlabItem {
  constructor(item) {
    if (item == null) {
      this.initItem();
    } else {
      let hp = Object.prototype.hasOwnProperty;
      this.name = hp.call(item, "name") ? item.name : null;
      this.olab_id = hp.call(item, "olab_id") ? item.olab_id : null;
      this.olab_type = hp.call(item, "olab_type") ? item.olab_type : null;

      // Additional fields for user only
      this.role = hp.call(item, "role") ? item.role : null;
      this.email = hp.call(item, "email") ? item.email : null;
      this.passwd = hp.call(item, "passwd") ? item.passwd : null;
      this.confirmed_passwd = hp.call(item, "confirmed_passwd")
        ? item.confirmed_passwd
        : null;
      this.photo = hp.call(item, "photo") ? item.photo : null;

      // Additional fields for chemical only
      this.mfr_id = hp.call(item, "mfr_id") ? item.mfr_id : null;
      this.qty = hp.call(item, "qty") ? item.qty : 0;
      this.lot_number = hp.call(item, "lot_number") ? item.lot_number : null;
      this.expired_date = hp.call(item, "expired_date")
        ? item.expired_date
        : null;

      // Additional fields for recipe only
      this.created_by = hp.call(item, "created_by") ? item.created_by : null;
      this.volume = hp.call(item, "volume") ? item.volume : 0;
      this.recipe_id = hp.call(item, "recipe_id") ? item.recipe_id : null;
      // Recipe solution, default unit is in litre
      this.units = hp.call(item, "units") ? item.units : "L";
      this.ingredients = hp.call(item, "ingredients") ? item.ingredients : [];
      this.purpose = hp.call(item, "purpose") ? item.purpose : null;
      this.note = hp.call(item, "note") ? item.note : null;

      // Additional fields for flask and incubator only
      this.type = hp.call(item, "type") ? item.type : null;
      this.size = hp.call(item, "size") ? item.size : null;
      this.description = hp.call(item, "description") ? item.description : null;
      this.temperature = hp.call(item, "temperature") ? item.temperature : null;

      // Additional fields for manufacturer only
      this.addr1 = hp.call(item, "addr1") ? item.addr1 : null;
      this.addr2 = hp.call(item, "addr2") ? item.addr2 : null;
      this.city = hp.call(item, "city") ? item.city : null;
      this.country = hp.call(item, "country") ? item.country : null;
      this.contact = hp.call(item, "contact") ? item.contact : null;
      this.phone = hp.call(item, "phone") ? item.phone : null;
      this.state = hp.call(item, "state") ? item.state : null;
      this.zipcode = hp.call(item, "zipcode") ? item.zipcode : null;

      // Additional fields for seed only
      this.plate_id = hp.call(item, "plate_id") ? item.plate_id : null;
      this.media_id = hp.call(item, "media_id") ? item.media_id : null;
      this.incubator_id = hp.call(item, "incubator_id")
        ? item.incubator_id
        : null;
      this.inoculum_size = hp.call(item, "inoculum_size")
        ? item.inoculum_size
        : null;
      this.flask_size = hp.call(item, "flask_size") ? item.flask_size : null;
      this.OD = hp.call(item, "OD") ? item.OD : null;
      this.pH = hp.call(item, "pH") ? item.pH : null;
      this.microscopic_observation = hp.call(item, "microscopic_observation")
        ? item.microscopic_observation
        : null;
    }
  }

  static getInvalidFeedbacks() {
    let fbMessage = {
      name: "Name is a required field",
      email: "email format is invalid",
      password: "password has to be 8 or more characters",
      password_confirmed: "passwords must match"
    };
    return fbMessage;
  }

  initItem() {
    console.log("OlabItem.initItem() ...");

    // Common fields: internal use
    this.olab_id = null;
    this.olab_type = null;

    // Common fields: User settable
    this.name = null;

    // Additional fields for user only
    this.role = null;
    this.email = null;
    this.passwd = null;
    this.confirmed_passwd = null;
    this.photo = null;

    // Additional fields for chemical, recipe and media only
    this.units = "L";
    this.ingredients = [];
    this.created_by = "";
    this.volume = 0;
    this.qty = 0;
    this.recipe_id = "";
    this.mfr_id = "";
    this.lot_number = "";
    this.expired_date = "";
    this.purpose = "";
    this.note = "";

    // Additional fields for flask and incubator only
    this.type = "";
    this.size = "";
    this.description = "";
    this.temperature = "";

    // Additional fields for manufacturer
    this.addr1 = "";
    this.addr2 = "";
    this.city = "";
    this.country = "";
    this.contact = "";
    this.phone = "";
    this.state = "";
    this.zipcode = "";

    // Additional fields for Seed
    this.plate_id = "";
    this.media_id = "";
    this.incubator_id = "";
    this.inoculum_size = "";
    this.flask_size = "";
    this.OD = "";
    this.pH = "";
    this.microscopic_observation = "";
  }

  set(item) {
    this.olab_id = item.olab_id;
    this.olab_type = item.olab_type;
    this.name = item.name;
    this.role = item.role;
    this.email = item.email;
    this.passwd = item.passwd;
    this.confirmed_passwd = item.confirmed_passwd;
    this.photo = item.photo;
    this.units = item.units;
    this.ingredients = item.ingredients;
    this.created_by = item.created_by;
    this.volume = item.volume;
    this.qty = item.qty;
    this.recipe_id = item.recipe_id;
    this.mfr_id = item.mfr_id;
    this.lot_number = item.lot_number;
    this.expired_date = item.expired_date;
    this.purpose = item.purpose;
    this.note = item.note;
    this.type = item.type;
    this.size = item.size;
    this.description = item.description;
    this.temperature = item.temperature;
    this.addr1 = item.addr1;
    this.addr2 = item.addr2;
    this.city = item.city;
    this.country = item.country;
    this.contact = item.contact;
    this.phone = item.phone;
    this.state = item.state;
    this.zipcode = item.zipcode;
    this.plate_id = item.plate_id;
    this.media_id = item.media_id;
    this.incubator_id = item.incubator_id;
    this.inoculum_size = item.inoculum_size;
    this.flask_size = item.flask_size;
    this.OD = item.OD;
    this.pH = item.pH;
    this.microscopic_observation = item.microscopic_observation;
  }

  _processItemField(fieldName) {
    return fieldName == null || fieldName == "" ? "is-invalid" : "is-valid";
  }

  getValidState(fieldName) {
    // OlabUtils.infoLog("getValidState: fieldName = " + fieldName);
    return this._processItemField(fieldName);
  }

  isGreaterThanZero(fieldName) {
    return fieldName > 0 ? "is-valid" : "is-invalid";
  }

  isValid(fieldName) {
    return this._processItemField(fieldName) == "is-valid";
  }

  getValidPWState(blankOK) {
    // console.log("passwd = " + this.passwd);
    let result = null;
    if (blankOK && !this.passwd) {
      result = "";
    } else if (this.passwd !== null) {
      if (this.passwd.length < 8) {
        result = "is-invalid";
      } else {
        result = "is-valid";
      }
    } else {
      result = "is-invalid";
    }
    return result;
  }

  getValidCPWState(blankOK) {
    // console.log("confirmed_passwd = " + this.confirmed_passwd);
    let result = null;
    if (blankOK && !this.passwd && !this.confirmed_passwd) {
      result = true;
    } else if (this.passwd !== null && this.confirmed_passwd !== null) {
      if (this.passwd !== this.confirmed_passwd) {
        result = "is-invalid";
      } else {
        result = "is-valid";
      }
    } else {
      result = "is-invalid";
    }
    return result;
  }

  isValidPW(pwd, cPwd, createCheck) {
    let result = false;
    if ((!createCheck || (pwd && cPwd && pwd.length >= 8)) && pwd === cPwd) {
      result = true;
    }
    return result;
  }

  isItemValid(selectedType, createCheck) {
    switch (selectedType) {
      case "project":
        return this.isValid(this.name);
      // break;
      case "recipe":
        console.log(
          "isItemValid case recipe run, ingredient number = ",
          this.ingredients.length
        );
        // check ingredient array and the other is valid
        return (
          this.ingredientsIsValid(this.ingredients) &&
          this.isValid(this.name) &&
          this.volume > 0
        );
      // break;
      case "media":
        return this.isValid(this.purpose) && this.volume > 0;
      // break;
      case "seed":
        return this.isValid(this.name);
      // break;
      case "user":
        return (
          this.isValid(this.name) &&
          OlabUtils.validateEmail(this.email) &&
          this.isValid(this.role) &&
          this.isValidPW(this.passwd, this.confirmed_passwd, createCheck)
        );
      // break;
      case "chemical":
        return (
          this.isValid(this.name) &&
          this.isValid(this.qty) &&
          this.isValid(this.mfr_id) &&
          this.isValid(this.lot_number)
        );
      // break;
      case "flask":
        return (
          this.isValid(this.name) &&
          this.isValid(this.type) &&
          this.isValid(this.size) &&
          this.isValid(this.description)
        );
      // break;
      case "incubator":
        return (
          this.isValid(this.name) &&
          this.isValid(this.type) &&
          this.isValid(this.temperature) &&
          this.isValid(this.description)
        );
      // break;
      case "manufacture":
        return (
          this.isValid(this.name) &&
          this.isValid(this.addr1) &&
          this.isValid(this.addr2) &&
          this.isValid(this.city) &&
          this.isValid(this.state) &&
          this.isValid(this.country) &&
          this.isValid(this.contact) &&
          OlabUtils.validateEmail(this.email) &&
          this.isValid(this.phone)
        );
      // break;
    }
    // console.log("OlabeItem.isItemValid: No selectedType found!!!");
    return false;
  }

  ingredientsIsValid(ingrArr) {
    let ingrArrValid = true;
    for (let i = 0; i < ingrArr.length; i++) {
      if (ingrArr[i].qty <= 0 || ingrArr[i].substance === "") {
        ingrArrValid = false;
        break;
      }
    }
    return ingrArrValid;
  }
}

export { OlabItem };
