import { firebase } from "@/firebase/config.js";
import { OlabAccess } from "./olabaccess.js";

class OlabUser {
  constructor(item) {
    if (item == null) {
      this.initUser();
    } else {
      this.auth_uid = item.auth_uid ? item.auth_uid : null;
      this.display_name = item.display_name ? item.display_name : null;
      this.email = item.email ? item.email : null;
      // "active", "pending", "suspended"
      this.status = item.status ? item.status : "active";
      this.olab_access = item.olab_access ? item.olab_access : new OlabAccess();
      this.created_date = item.created_date ? item.created_date : null;
      this.updated_date = item.updated_date ? item.updated_date : null;
    }
  }

  static getCreateOlabUserConverter() {
    // Firestore data converter
    let olabUserConverter = {
      toFirestore(item) {
        return {
          auth_uid: item.auth_uid,
          display_name: item.display_name,
          email: item.email,
          status: item.status,
          olab_access: {
            visible: item.olab_access.visible,
            allow: item.olab_access.allow,
            roles: item.olab_access.roles,
            groups: item.olab_access.groups
          },
          created_date: firebase.firestore.FieldValue.serverTimestamp()
        };
      },
      fromFirestore(snapshot, options) {
        const data = snapshot.data(options);
        return new OlabUser(data);
      }
    };
    return olabUserConverter;
  }

  static getUpdateOlabUserConverter() {
    // Firestore data converter
    let olabUserConverter = {
      toFirestore(item) {
        return {
          auth_uid: item.auth_uid,
          display_name: item.display_name,
          email: item.email,
          status: item.status,
          olab_access: {
            visible: item.olab_access.visible,
            allow: item.olab_access.allow,
            roles: item.olab_access.roles,
            groups: item.olab_access.groups
          },
          updated_date: firebase.firestore.FieldValue.serverTimestamp()
        };
      },
      fromFirestore(snapshot, options) {
        const data = snapshot.data(options);
        return new OlabUser(data);
      }
    };
    return olabUserConverter;
  }

  initUser() {
    console.log("OlabUser.initUser() ...");
    this.auth_uid = null;
    this.display_name = null;
    this.email = null;
    this.status = "active";
    this.olab_access = new OlabAccess();
    this.created_date = null;
    this.updated_date = null;
  }

  set(item) {
    console.log("OlabUser.set() ...");
    this.auth_uid = item.auth_uid;
    this.display_name = item.display_name;
    this.email = item.email;
    this.status = item.status;
    this.olab_access = item.olab_access;
    this.created_date = item.created_date;
    this.updated_date = item.updated_date;
  }

  static infoLog(item) {
    console.log("OlabUser.infoLong() ... printing ...");
    console.log("item.auth_uid =", item.auth_uid);
    console.log("item.display_name =", item.display_name);
    console.log("item.email =", item.email);
    console.log("item.status =", item.status);
    console.log("item.olab_access.visible =", item.olab_access.visible);
    console.log("item.olab_access.allow =", item.olab_access.allow);
    console.log("item.olab_access.roles =", item.olab_access.roles);
    for (const key in item.olab_access.roles) {
      // check if the property/key is defined in the object itself, not in parent
      if (Object.prototype.hasOwnProperty.call(item.olab_access.roles, key)) {
        console.log("roles: ", key, " =", item.olab_access.roles[key]);
      }
    }
    console.log("item.olab_access.groups =", item.olab_access.groups);
    for (const key in item.olab_access.groups) {
      // check if the property/key is defined in the object itself, not in parent
      if (Object.prototype.hasOwnProperty.call(item.olab_access.groups, key)) {
        console.log("groups: ", key, " =", item.olab_access.groups[key]);
      }
    }
    console.log("item.created_date =", item.created_date);
    console.log("item.updated_date =", item.updated_date);
  }
}

export { OlabUser };
