<template>
  <div class="container text-center mt-3">
    <img alt="OLAB Bioscience logo" src="../assets/OLAB.svg" />
    <div class="mb-3">
      <span class="text-info">Synthetic Biology</span>
      <span class="text-success"> made easy</span>
    </div>
    <div class="row justify-content-center">
      <div class="col-10 col-md-10 col-lg-8 col-xl-7">
        <p class="lead text-danger">
          <font-awesome-icon
            class="ml-1 text-success"
            icon="seedling"
          ></font-awesome-icon>
          Members Only Site
          <font-awesome-icon
            class="ml-1 text-warning"
            icon="dna"
          ></font-awesome-icon>
        </p>
        <router-link
          class="btn btn-outline-primary mr-2"
          to="/blueberry"
          v-if="user"
          >Project Blueberry</router-link
        >
        <hr v-if="user" />
      </div>
    </div>
    <div class="mt-2 container text-center" v-if="user">
      <div class="card bg-light mb-3">
        <div class="card-header">Links</div>
        <p>
          <a
            class="collapsed btn btn-outline-primary mt-3 mx-1"
            data-toggle="collapse"
            data-target="#services"
            role="button"
            aria-expanded="true"
            aria-controls="services"
          >
            Services
          </a>
        </p>
        <div class="collapse" id="services">
          <div class="card card-body">
            <!-- <h5 class="card-title">XXXX</h5> -->
            <div class="card-text">
              <div class="my-3 row justify-content-center">
                <div class="col-10">
                  <div class="card border-dark mb-3">
                    <div class="card-header text-dark">OLAB Bioscience</div>
                    <div class="card-body text-dark">
                      <!-- <h5 class="card-title"></h5> -->
                      <div class="card-text my-2">
                        <a href="https://web.olabbio.com">Offical Website</a>
                      </div>
                      <hr />
                      <div class="card-text my-2">
                        <a href="https://google.com">Search</a>
                      </div>
                      <!-- <p class="card-text">User Account</p> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="my-2">
        <p>
          Please submit your questions, bugs, feature requests, and
          contributions at
          <a
            href="mailto:contact@olabbio.com?subject=Required Action: CJCJ-OLAB Project"
            >contact@olabbio.com</a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "Home",
  props: ["user", "dbInfo"],
  components: {
    FontAwesomeIcon
  }
};
</script>

<style scoped>
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
