<template>
  <div>
    <div class="modal fade" id="addItem">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Add:
              <span class="text-dark text-truncate">
                {{ selectedTypeName }}
              </span>
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <!-- .modal-header -->
          <div class="modal-body">
            <form v-on:submit.prevent="onSubmit">
              <h1>Incubator Form</h1>
              <div class="form-row">
                <div class="form-group col-md-4">
                  <label for="inputName">Name</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      v-bind:class="item.getValidState(item.name)"
                      v-model="item.name"
                    />
                  </div>
                </div>
                <div class="form-group col-md-4">
                  <label for="inputType">Type</label>
                  <select
                    class="form-control"
                    v-bind:class="item.getValidState(item.type)"
                    v-model="item.type"
                  >
                    <option disabled>Select...</option>
                    <option>Stationary</option>
                    <option>Shaking</option>
                  </select>
                </div>
                <div class="form-group col-md-4">
                  <label for="inputTemp">Temperature</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      v-bind:class="item.getValidState(item.temperature)"
                      v-model="item.temperature"
                    />
                    <div class="input-group-append">
                      <span class="input-group-text"> &#8451; </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="inputDescrip">Description</label>
                <textarea
                  type="textarea"
                  class="form-control"
                  v-bind:class="item.getValidState(item.description)"
                  v-model="item.description"
                  id="inputNote"
                ></textarea>
              </div>
              <div class="form-group">
                <label for="inputNote">Note</label>
                <textarea
                  type="textarea"
                  class="form-control"
                  v-model="item.note"
                  id="inputNote"
                ></textarea>
              </div>
            </form>
          </div>
          <!-- .modal-body -->

          <div class="modal-footer">
            <button
              type="button"
              v-bind:disabled="itemAddIsDisabled == true"
              class="btn btn-primary"
              v-on:click="handleAddItem(item)"
            >
              <span
                v-if="statusObj.progress"
                class="spinner-border spinner-border-sm text-light"
              ></span>
              Add
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancel
            </button>
          </div>
          <!-- .modal-footer -->
        </div>
        <!-- .modal-content -->
      </div>
      <!-- .modal-dialog .modal-lg-->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { OlabItem } from "@/olab/olabitem.js";

export default {
  name: "incubatoraddform",
  data() {
    return {
      selectedType: "incubator",
      selectedTypeName: "Incubator",
      item: new OlabItem(),
      invalidFB: OlabItem.getInvalidFeedbacks()
    };
  },
  props: ["statusObj", "errorObj"],
  emits: ["emitStatusMessage", "emitErrorMessage", "addItem"],
  mounted() {
    console.log("IncubatorAddForm mounted ...");
    // Reset status and error
    this.doEmitStatusMessage(null, false);
    this.doEmitErrorMessage(null);
  },
  computed: {
    itemAddIsDisabled() {
      return !this.item.isItemValid(this.selectedType, true);
    }
  },
  methods: {
    handleAddItem(item) {
      this.item.olab_id = "system generated olab id number";
      this.item.olab_type = this.selectedType;
      console.log("TODO: handleAddItem =", item);
      this.$emit("addItem", this.item);
    },
    doEmitStatusMessage(statusMsg, statusProgress) {
      this.$emit("emitStatusMessage", statusMsg, statusProgress);
    },
    doEmitErrorMessage(errMsg) {
      this.$emit("emitErrorMessage", errMsg);
    },
    closeStatus() {
      // console.log("Close Status Alert ...");
      this.emitStatusMessage(null, false);
    },
    closeError() {
      // console.log("Close Error Alert ...");
      this.emitErrorMessage(null);
    },
    onSubmit() {
      // This is a NOP to prevent page reload on return key
      console.log("After Submit");
    }
  }
};
</script>
