<template>
  <div class="plateface">
    <div class="container text-left mt-3">
      <FormSelector :selectedTypeName="selectedTypeName" />
      <PlateAddForm
        :statusObj="statusObj"
        :errorObj="errorObj"
        @emitStatusMessage="emitStatusMessage"
        @emitErrorMessage="emitErrorMessage"
      />
      <PlateEditForm
        :statusObj="statusObj"
        :errorObj="errorObj"
        @emitStatusMessage="emitStatusMessage"
        @emitErrorMessage="emitErrorMessage"
      />
      <PlateViewForm
        :statusObj="statusObj"
        :errorObj="errorObj"
        @emitStatusMessage="emitStatusMessage"
        @emitErrorMessage="emitErrorMessage"
      />
    </div>
    <div class="container">
      <div class="row justify-content-center">
        <div
          v-if="errorObj.message"
          class="col-12 alert alert-danger text-center px-3"
        >
          {{ errorObj.message }}
          <div class="text-center mt-2">
            <button
              type="button"
              class="btn btn-danger"
              v-on:click="closeError()"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="container text-left">
      <p>TODO: Implement capture result display here</p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import FormSelector from "@/components/blueberry/FormSelector";
import PlateAddForm from "@/components/blueberry/PlateAddForm";
import PlateEditForm from "@/components/blueberry/PlateEditForm";
import PlateViewForm from "@/components/blueberry/PlateViewForm";

export default {
  name: "olabplate",
  data() {
    return {
      selectedType: "plate",
      selectedTypeName: "Plate"
    };
  },
  components: {
    FormSelector,
    PlateAddForm,
    PlateEditForm,
    PlateViewForm
  },
  props: ["statusObj", "errorObj"],
  emits: ["setStatusMessage", "setErrorMessage"],
  mounted() {
    console.log("Plate mounted ...");
    // Reset status and error
    this.emitStatusMessage(null, false);
    this.emitErrorMessage(null);
  },
  methods: {
    emitStatusMessage(statusMsg, statusProgress) {
      this.$emit("setStatusMessage", statusMsg, statusProgress);
    },
    emitErrorMessage(errMsg) {
      this.$emit("setErrorMessage", errMsg);
    },
    closeStatus() {
      // console.log("Close Status Alert ...");
      this.emitStatusMessage(null, false);
    },
    closeError() {
      // console.log("Close Error Alert ...");
      this.emitErrorMessage(null);
    }
  }
};
</script>

<style scoped>
.seedface {
  padding: 50px 0;
  background-color: #fdf2e9;
}
</style>
