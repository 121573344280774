<template>
  <div>
    <div class="modal fade" id="addItem">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Add:
              <span class="text-dark text-truncate">
                {{ selectedTypeName }}
              </span>
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <!-- .modal-header -->
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label for="ip_name" class="form-control-label">
                  <span>Name:</span>
                </label>
                <input
                  id="ip_name"
                  class="form-control"
                  v-bind:class="item.getValidState(item.name)"
                  type="text"
                  placeholder="Name"
                  v-model="item.name"
                />
                <!-- <div class="invalid-feedback">{{ invalidFB.name }}</div> -->
              </div>
              <div v-if="selectedType === 'user'" class="form-group">
                <label for="ip_email" class="form-control-label">
                  <span>Email:</span>
                </label>
                <input
                  id="ip_email"
                  class="form-control"
                  v-bind:class="validEmailState(item.email)"
                  type="email"
                  placeholder="Email Address"
                  v-model="item.email"
                />
                <!-- <div class="invalid-feedback">{{ invalidFB.email }}</div> -->
              </div>
              <div v-if="selectedType === 'user'" class="form-group">
                <label for="ip_systemRole" class="form-control-label">
                  <span>System Role:</span>
                </label>
                <select
                  id="ip_systemRole"
                  class="form-control"
                  v-bind:class="item.getValidState(item.role)"
                  v-model="item.role"
                >
                  <option disabled value="null">Please select one</option>
                  <option value="admin">Admin</option>
                  <option value="user">User</option>
                </select>
                <!-- <span>selected = {{ item.role }}</span> -->
              </div>
              <div v-if="selectedType === 'user'" class="form-group">
                <label for="ip_cjPassword" class="form-control-label">
                  <span>Password:</span>
                </label>
                <div class="form-row" id="ip_cjPassword">
                  <section class="col-sm-6">
                    <input
                      class="form-control"
                      v-bind:class="item.getValidPWState(false)"
                      type="password"
                      required
                      placeholder="Password"
                      v-model="item.passwd"
                    />
                    <div class="invalid-feedback">
                      {{ invalidFB.password }}
                    </div>
                  </section>
                  <section class="col-sm-6">
                    <input
                      class="form-control"
                      v-bind:class="item.getValidCPWState(false)"
                      type="password"
                      required
                      placeholder="Repeat Password"
                      v-model="item.confirmed_passwd"
                    />
                    <div class="invalid-feedback">
                      {{ invalidFB.password_confirmed }}
                    </div>
                  </section>
                </div>
              </div>
              <!-- .form-group -->
            </form>
          </div>
          <!-- .modal-body -->

          <div class="modal-footer">
            <button
              type="button"
              v-bind:disabled="itemAddIsDisabled == true"
              class="btn btn-primary"
              v-on:click="handleAddItem(item)"
            >
              <span
                v-if="statusObj.progress"
                class="spinner-border spinner-border-sm text-light"
              ></span>
              Add
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancel
            </button>
          </div>
          <!-- .modal-footer -->
        </div>
        <!-- .modal-content -->
      </div>
      <!-- .modal-dialog .modal-lg-->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { OlabItem } from "@/olab/olabitem.js";
import { OlabUtils } from "@/olab/olabutils.js";

export default {
  name: "useraddform",
  data() {
    return {
      selectedType: "user",
      selectedTypeName: "User",
      item: new OlabItem(),
      invalidFB: OlabItem.getInvalidFeedbacks()
    };
  },
  props: ["statusObj", "errorObj"],
  emits: ["emitStatusMessage", "emitErrorMessage"],
  mounted() {
    console.log("UserAddForm mounted ...");
    // Reset status and error
    this.doEmitStatusMessage(null, false);
    this.doEmitErrorMessage(null);
  },
  computed: {
    itemAddIsDisabled() {
      return !this.item.isItemValid(this.selectedType, true);
    }
  },
  methods: {
    handleAddItem(item) {
      console.log("TODO: handleAddItem =", item);
    },
    validEmailState(em) {
      console.log("validEmailState: email = " + em);
      return OlabUtils.validateEmail(em) ? "is-valid" : "is-invalid";
    },
    doEmitStatusMessage(statusMsg, statusProgress) {
      this.$emit("emitStatusMessage", statusMsg, statusProgress);
    },
    doEmitErrorMessage(errMsg) {
      this.$emit("emitErrorMessage", errMsg);
    },
    closeStatus() {
      // console.log("Close Status Alert ...");
      this.emitStatusMessage(null, false);
    },
    closeError() {
      // console.log("Close Error Alert ...");
      this.emitErrorMessage(null);
    }
  }
};
</script>
