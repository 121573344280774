<template>
  <div>
    <div class="modal fade" id="addItem">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Add:
              <span class="text-dark text-truncate">
                {{ selectedTypeName }}
              </span>
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <!-- .modal-header -->
          <div class="modal-body">
            <form>
              <h1>Plate Form</h1>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="inputMedia">Media ID</label>
                  <select class="form-control">
                    <option selected>Select 1</option>
                    <option>Select 2</option>
                    <option>Select 3</option>
                  </select>
                </div>
                <div class="form-group col-md-6">
                  <label for="inputStrain">Strain ID</label>
                  <select class="form-control">
                    <option selected>Select 1</option>
                    <option>Select 2</option>
                    <option>Select 3</option>
                  </select>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-3">
                  <label for="inputStartTime">Start Time</label>
                  <div class="input-group">
                    <input type="time" class="form-control" />
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="inputEndTime">End Time</label>
                  <div class="input-group">
                    <input type="time" class="form-control" />
                  </div>
                </div>
                <div class="form-group col-md-4">
                  <label for="inputCultTime"> Culture Time</label>
                  <div class="input-group">
                    <input
                      type="text"
                      readonly
                      class="form-control-plaintext text-info"
                      id="staticCultTime"
                      value=" to be autogenerated"
                    />
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="inputIncubator">Incubator</label>
                  <select class="form-control">
                    <option selected>Select 1</option>
                    <option>Select 2</option>
                    <option>Select 3</option>
                    <option>Select 4</option>
                  </select>
                </div>
                <div class="form-group col-md-6">
                  <label for="inputTemp"> Temperature</label>
                  <div class="input-group">
                    <input
                      type="text"
                      readonly
                      class="form-control-plaintext text-info"
                      id="staticTemp"
                      value=" to be autogenerated"
                    />
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="inputStationary">Growth</label>
                <div class="input-group">
                  <input type="file" id="img" name="img" accept="image/*" />
                </div>
              </div>
              <div class="form-group">
                <label for="inputNote">Note</label>
                <textarea
                  type="textarea"
                  class="form-control"
                  id="inputNote"
                ></textarea>
              </div>
            </form>
          </div>
          <!-- .modal-body -->

          <div class="modal-footer">
            <button
              type="button"
              v-bind:disabled="itemAddIsDisabled == true"
              class="btn btn-primary"
              v-on:click="handleAddItem(item)"
            >
              <span
                v-if="statusObj.progress"
                class="spinner-border spinner-border-sm text-light"
              ></span>
              Add
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancel
            </button>
          </div>
          <!-- .modal-footer -->
        </div>
        <!-- .modal-content -->
      </div>
      <!-- .modal-dialog .modal-lg-->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { OlabItem } from "@/olab/olabitem.js";

export default {
  name: "plateaddform",
  data() {
    return {
      selectedType: "plate",
      selectedTypeName: "Plate",
      item: new OlabItem(),
      invalidFB: OlabItem.getInvalidFeedbacks()
    };
  },
  props: ["statusObj", "errorObj"],
  emits: ["emitStatusMessage", "emitErrorMessage"],
  mounted() {
    console.log("PlateAddForm mounted ...");
    // Reset status and error
    this.doEmitStatusMessage(null, false);
    this.doEmitErrorMessage(null);
  },
  computed: {
    itemAddIsDisabled() {
      return !this.item.isItemValid(this.selectedType, true);
    }
  },
  methods: {
    handleAddItem(item) {
      console.log("TODO: handleAddItem =", item);
    },
    doEmitStatusMessage(statusMsg, statusProgress) {
      this.$emit("emitStatusMessage", statusMsg, statusProgress);
    },
    doEmitErrorMessage(errMsg) {
      this.$emit("emitErrorMessage", errMsg);
    },
    closeStatus() {
      // console.log("Close Status Alert ...");
      this.emitStatusMessage(null, false);
    },
    closeError() {
      // console.log("Close Error Alert ...");
      this.emitErrorMessage(null);
    }
  }
};
</script>
