<template>
  <div>
    <div class="modal fade" id="addItem">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Add:
              <span class="text-dark text-truncate">
                {{ selectedTypeName }}
              </span>
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <!-- .modal-header -->
          <div class="modal-body">
            <div class="col-md-12">
              <form class="mb-3" v-on:submit.prevent="onSubmit">
                <div class="card">
                  <div class="card-header">Media Recipe</div>
                  <div class="card-body">
                    <select
                      class="form-control text-primary"
                      v-bind:class="item.getValidState(item.name)"
                      v-model="recipeIndex"
                    >
                      <option disabled value="-1">Select a recipe</option>
                      <option
                        v-for="(recipe, j) in recipes"
                        :value="j"
                        v-bind:key="recipe.name"
                      >
                        {{ recipe.olab_id }} - {{ recipe.name }}
                      </option>
                    </select>
                    <div
                      v-if="recipeIndex >= 0"
                      class="form-group form-inline mt-4"
                    >
                      <label class="text-dark">Media Volume</label>
                      <span>
                        <input
                          name="Volume"
                          type="number"
                          min="0"
                          placeholder="Volume"
                          class="ml-2 form-control text-primary"
                          v-bind:class="item.isGreaterThanZero(item.volume)"
                          style="width: 120px; text-align: center"
                          v-model="item.volume"
                        />
                      </span>
                      <span class="mx-1">
                        {{ (item.units = recipes[recipeIndex].units) }}
                      </span>
                    </div>

                    <div v-if="recipeIndex >= 0">
                      <button
                        v-if="collapse === false"
                        type="button"
                        class="btn"
                        v-on:click="collapse = true"
                      >
                        <font-awesome-icon icon="chevron-up">
                          &nbsp;</font-awesome-icon
                        >
                      </button>
                      <button
                        v-else
                        type="button"
                        class="btn"
                        v-on:click="collapse = false"
                      >
                        <font-awesome-icon icon="chevron-down">
                          &nbsp;</font-awesome-icon
                        >
                      </button>
                      <span>Ingredients</span>
                      <table class="table" v-show="!collapse">
                        <thead>
                          <th scope="col">Substance</th>
                          <th scope="col">
                            Recipe <br />
                            {{ recipes[recipeIndex].volume }}
                            {{ recipes[recipeIndex].units }}
                          </th>
                          <th scope="col">
                            Media <br />
                            {{ item.volume }} {{ item.units }}
                          </th>
                        </thead>
                        <tbody>
                          <tr
                            v-for="ingredient in recipes[recipeIndex]
                              .ingredients"
                            v-bind:key="ingredient"
                          >
                            <th scope="row">{{ ingredient.substance }}</th>
                            <td>{{ ingredient.qty }} {{ ingredient.unit }}</td>
                            <td>
                              {{ calculateSubstanceValue(ingredient.qty) }}
                              {{ ingredient.unit }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </form>
              <form class="mb-2" v-on:submit.prevent="onSubmit">
                <div class="form-group">
                  <label>Purpose</label>
                  <input
                    name="purpose"
                    type="text"
                    placeholder="Purpose"
                    class="form-control text-primary"
                    v-bind:class="item.getValidState(item.purpose)"
                    style="text-align: left"
                    v-model="item.purpose"
                  />
                </div>
                <div class="form-group">
                  <label>Note</label>
                  <textarea
                    name="note"
                    type="text"
                    placeholder="Note"
                    class="form-control text-primary"
                    style="text-align: left"
                    rows="3"
                    v-model="item.note"
                  />
                </div>
              </form>
            </div>
          </div>
          <!-- .modal-body -->

          <div class="modal-footer">
            <button
              type="button"
              v-bind:disabled="itemAddIsDisabled == true"
              class="btn btn-primary"
              v-on:click="handleAddItem(item)"
            >
              <span
                v-if="statusObj.progress"
                class="spinner-border spinner-border-sm text-light"
              ></span>
              Add
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancel
            </button>
          </div>
          <!-- .modal-footer -->
        </div>
        <!-- .modal-content -->
      </div>
      <!-- .modal-dialog .modal-lg-->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { OlabItem } from "@/olab/olabitem.js";
// import { OlabUtils } from "@/olab/olabutils.js";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { recipes } from "@/assets/data/blueberry/recipes.js";
// import { users } from "@/assets/data/blueberry/users.js";

export default {
  name: "mediaaddform",
  components: {
    FontAwesomeIcon
  },
  data() {
    return {
      selectedType: "media",
      selectedTypeName: "Media",
      item: new OlabItem(),
      recipeIndex: -1,
      recipes,
      collapse: false
    };
  },
  props: ["statusObj", "errorObj"],
  emits: ["emitStatusMessage", "emitErrorMessage"],
  mounted() {
    console.log("MediaAddForm mounted ...");
    // Reset status and error
    this.doEmitStatusMessage(null, false);
    this.doEmitErrorMessage(null);
  },
  computed: {
    itemAddIsDisabled() {
      return !this.item.isItemValid(this.selectedType, true);
    }
  },
  methods: {
    handleAddItem(item) {
      // item.createBy = this.createByName;
      // item.recipeID = this.recipes[this.recipeIndex].olab_id;
      // item.name = this.recipes[this.recipeIndex].name;
      // item.olab_id = "ID system generated";
      // item.recipeName = this.recipes[this.recipeIndex].name;
      // item.units = this.recipes[this.recipeIndex].units;

      console.log("TODO: handleAddItem =", item);
    },
    doEmitStatusMessage(statusMsg, statusProgress) {
      this.$emit("emitStatusMessage", statusMsg, statusProgress);
    },
    doEmitErrorMessage(errMsg) {
      this.$emit("emitErrorMessage", errMsg);
    },
    closeStatus() {
      // console.log("Close Status Alert ...");
      this.emitStatusMessage(null, false);
    },
    closeError() {
      // console.log("Close Error Alert ...");
      this.emitErrorMessage(null);
    },
    calculateSubstanceValue(k) {
      let value = 0;
      if (this.item.volume > 0) {
        value = (k * this.item.volume) / recipes[this.recipeIndex].volume;
        return value;
      } else {
        return value;
      }
    },
    onSubmit() {
      // This is a NOP to prevent page reload on return key
      console.log("After Submit");
    }
  },
  watch: {
    recipeIndex() {
      this.item.recipeID = recipes[this.recipeIndex].olab_id;
      this.item.name = recipes[this.recipeIndex].name;
      this.item.units = recipes[this.recipeIndex].units;
    }
  }
};
</script>
