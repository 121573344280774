<template>
  <div>
    <h1>
      <span class="mr-2">{{ selectedTypeName }} Form</span
      ><span>
        <button
          type="button"
          class="btn btn-info"
          data-toggle="modal"
          data-target="#addItem"
          data-backdrop="static"
          data-keyboard="false"
        >
          <font-awesome-icon icon="plus" />
        </button>
      </span>
      <span class="ml-1">
        <button
          type="button"
          class="btn btn-info"
          data-toggle="modal"
          data-target="#editItem"
          data-backdrop="static"
          data-keyboard="false"
        >
          <font-awesome-icon icon="pen" />
        </button>
      </span>
      <span class="ml-1">
        <button
          type="button"
          class="btn btn-info"
          data-toggle="modal"
          data-target="#viewItem"
          data-backdrop="static"
          data-keyboard="false"
        >
          <font-awesome-icon icon="eye" />
        </button>
      </span>
      <span class="ml-1">
        <button
          type="button"
          class="btn btn-info"
          v-on:click="$router.push('/blueberry')"
        >
          <font-awesome-icon icon="times" />
        </button>
      </span>
    </h1>
  </div>
</template>

<script>
// @ is an alias to /src
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "formselector",
  data() {
    return {};
  },
  components: {
    FontAwesomeIcon
  },
  props: ["selectedTypeName"]
};
</script>
