<template>
  <div>
    <div class="modal fade" id="addItem">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Add:
              <span class="text-dark text-truncate">
                {{ selectedTypeName }}
              </span>
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <!-- .modal-header -->
          <div class="modal-body">
            <form v-on:submit.prevent="onSubmit">
              <div class="form-group">
                <label for="inputName">Name</label>
                <input
                  type="text"
                  class="form-control"
                  v-bind:class="item.getValidState(item.name)"
                  id="inputName"
                  placeholder="Name"
                  v-model="item.name"
                />
              </div>
              <div class="form-row">
                <div class="form-group col-md-4">
                  <label for="inputQuantity">Quantity</label>
                  <input
                    type="number"
                    class="form-control"
                    v-bind:class="item.isGreaterThanZero(item.qty)"
                    id="inputQuantity"
                    v-model="item.qty"
                  />
                </div>
                <div class="form-group col-md-4">
                  <label for="inputUnit">Unit</label>
                  <select class="form-control" v-model="item.units">
                    <option selected>Select...</option>
                    <option>Kg</option>
                    <option>g</option>
                    <option>mg</option>
                    <option>L</option>
                    <option>mL</option>
                  </select>
                </div>
                <div class="form-group col-md-4">
                  <label for="inputManID">Manufacturer ID</label>
                  <select
                    class="form-control"
                    v-bind:class="item.getValidState(item.mfr_id)"
                    v-model="mfrIndex"
                  >
                    <option disabled>Select...</option>
                    <option
                      v-for="(manufacturer, j) in manufacturers"
                      :value="j"
                      v-bind:key="manufacturer.name"
                    >
                      {{ manufacturer.olab_id }}
                      {{ manufacturer.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="inputLot">Lot Number</label>
                  <input
                    type="text"
                    class="form-control"
                    v-bind:class="item.getValidState(item.lot_number)"
                    id="inputLot"
                    v-model="item.lot_number"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label for="inputExpiryDate">Expiry Date</label>
                  <input
                    type="date"
                    class="form-control"
                    v-bind:class="item.getValidState(item.expired_date)"
                    id="inputExpiryDate"
                    v-model="item.expired_date"
                  />
                </div>
              </div>
              <div class="form-group">
                <label for="inputNote">Note</label>
                <textarea
                  type="textarea"
                  class="form-control"
                  id="inputNote"
                  v-model="item.note"
                  @click="mfrUpdate"
                ></textarea>
              </div>
            </form>
          </div>
          <!-- .modal-body -->

          <div class="modal-footer">
            <button
              type="button"
              v-bind:disabled="itemAddIsDisabled == true"
              class="btn btn-primary"
              v-on:click="handleAddItem(item)"
            >
              <span
                v-if="statusObj.progress"
                class="spinner-border spinner-border-sm text-light"
              ></span>
              Add
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancel
            </button>
          </div>
          <!-- .modal-footer -->
        </div>
        <!-- .modal-content -->
      </div>
      <!-- .modal-dialog .modal-lg-->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { OlabItem } from "@/olab/olabitem.js";
import { manufacturers } from "@/assets/data/blueberry/manufacturers.js";

export default {
  name: "chemicaladdform",
  data() {
    return {
      selectedType: "chemical",
      selectedTypeName: "Chemical",
      item: new OlabItem(),
      invalidFB: OlabItem.getInvalidFeedbacks(),
      manufacturers,
      mfrIndex: -1,
      mfrName: ""
    };
  },
  props: ["statusObj", "errorObj"],
  emits: ["emitStatusMessage", "emitErrorMessage"],
  mounted() {
    console.log("ChemicalAddForm mounted ...");
    // Reset status and error
    this.doEmitStatusMessage(null, false);
    this.doEmitErrorMessage(null);
  },
  computed: {
    itemAddIsDisabled() {
      return !this.item.isItemValid(this.selectedType, true);
    }
  },
  methods: {
    handleAddItem(item) {
      this.item.olab_id = "system generated olab id number";
      this.item.olab_type = this.selectedType;
      console.log("TODO: handleAddItem =", item);
      this.$emit("addItem", this.item);
      this.$emit("addMfrName", this.mfrName);
    },
    doEmitStatusMessage(statusMsg, statusProgress) {
      this.$emit("emitStatusMessage", statusMsg, statusProgress);
    },
    doEmitErrorMessage(errMsg) {
      this.$emit("emitErrorMessage", errMsg);
    },
    closeStatus() {
      // console.log("Close Status Alert ...");
      this.emitStatusMessage(null, false);
    },
    closeError() {
      // console.log("Close Error Alert ...");
      this.emitErrorMessage(null);
    },
    onSubmit() {
      // This is a NOP to prevent page reload on return key
      console.log("After Submit");
    },
    mfrUpdate() {
      this.item.mfr_id = this.manufacturers[this.mfrIndex].olab_id;
    }
  },
  watch: {
    mfrIndex() {
      this.item.mfr_id = manufacturers[this.mfrIndex].olab_id;
      this.mfrName = manufacturers[this.mfrIndex].name;
    }
  }
};
</script>
