<template>
  <div>
    <div class="modal fade" id="viewItem">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              View:
              <span class="text-dark text-truncate">
                {{ selectedTypeName }}
              </span>
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <!-- .modal-header -->
          <div class="modal-body">
            <div class="py-3 text-wrap">
              <span class="mr-1 h5">OLAB ID:</span>
              <span class="ml-1 h5">{{ item.olab_id }}</span>
            </div>
            <div class="py-1 text-wrap">
              <span class="mr-1 h5">Recipe Name:</span>
              <span class="ml-1 h5">{{ item.name }}</span>
            </div>
            <table class="table">
              <thead>
                <th scope="col">Substance</th>
                <th scope="col">
                  Recipe <br />
                  {{ recipe.volume }}
                  {{ recipe.units }}
                </th>
              </thead>
              <tbody>
                <tr
                  v-for="ingredient in recipe.ingredients"
                  v-bind:key="ingredient"
                >
                  <th scope="row">{{ ingredient.substance }}</th>
                  <td>{{ ingredient.qty }} {{ ingredient.unit }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- .modal-body -->

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancel
            </button>
          </div>
          <!-- .modal-footer -->
        </div>
        <!-- .modal-content -->
      </div>
      <!-- .modal-dialog .modal-lg-->
    </div>
  </div>
</template>

<script>
export default {
  name: "recipeviewform",
  data() {
    return {
      selectedType: "recipe",
      selectedTypeName: "Recipe",
      item: {
        olab_id: "RCP-0001",
        name: "LB liquid medium"
      },
      recipe: {
        olab_id: "RCP-0001",
        name: "LB liquid medium",
        volume: 1000,
        units: "mL",
        ingredients: [
          {
            qty: 10,
            unit: "g",
            substance: "Tryptone"
          },
          {
            qty: 10,
            unit: "g",
            substance: "NaCl"
          },
          {
            qty: 5,
            unit: "g",
            substance: "Yeast extract"
          }
        ]
      }
    };
  },
  props: ["statusObj", "errorObj"],
  emits: ["emitStatusMessage", "emitErrorMessage"],
  mounted() {
    console.log("RecipeViewForm mounted ...");
    // Reset status and error
    this.doEmitStatusMessage(null, false);
    this.doEmitErrorMessage(null);
  },
  methods: {
    doEmitStatusMessage(statusMsg, statusProgress) {
      this.$emit("emitStatusMessage", statusMsg, statusProgress);
    },
    doEmitErrorMessage(errMsg) {
      this.$emit("emitErrorMessage", errMsg);
    },
    closeStatus() {
      // console.log("Close Status Alert ...");
      this.emitStatusMessage(null, false);
    },
    closeError() {
      // console.log("Close Error Alert ...");
      this.emitErrorMessage(null);
    }
  }
};
</script>
