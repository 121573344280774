class OlabUtils {
  static fixFloatTo(value, digit) {
    return parseFloat(value).toFixed(digit);
  }

  static getErrorMessage(err) {
    return `${err.response.statusText}: ${err.response.data.message}`;
  }

  static errorLog(err) {
    console.err(err);
    // For debugging
    alert(OlabUtils.getErrorMessage(err));
  }

  static subStrB4Space(str) {
    return !str || str === "" ? "" : str.substring(0, str.indexOf(" ")).trim();
  }

  static validateEmail(em) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(em).toLowerCase());
  }
}

export { OlabUtils };
