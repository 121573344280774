<template>
  <div>
    <div class="modal fade" id="viewItem">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              View:
              <span class="text-dark text-truncate">
                {{ selectedTypeName }}
              </span>
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <!-- .modal-header -->
          <div class="modal-body">
            <div class="py-3 text-wrap">
              <span class="mr-1 h5">OLAB ID:</span>
              <span class="ml-1 h5">{{ item.olab_id }}</span>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <span class="mr-1">Plate ID:</span>
                <span class="ml-1">{{ item.plate_id }}</span>
              </div>
              <div class="form-group col-md-6">
                <span class="mr-1">Media ID:</span>
                <span class="ml-1">{{ item.media_id }}</span>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-4">
                <span class="mr-1">Start Time:</span>
                <span class="ml-1">{{ item.start_time }}</span>
              </div>
              <div class="form-group col-md-4">
                <span class="mr-1">End Time:</span>
                <span class="ml-1">{{ item.end_time }}</span>
              </div>
              <div class="form-group col-md-4">
                <span class="mr-1">Culture Time:</span>
                <span class="ml-1">{{ item.culture_time }}</span>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-4">
                <span class="mr-1">Working Volume:</span>
                <span class="ml-1">{{ item.working_volume }}</span>
              </div>
              <div class="form-group col-md-4">
                <span class="mr-1">Flask Size:</span>
                <span class="ml-1">{{ item.flask_size }}</span>
              </div>
              <div class="form-group col-md-4">
                <span class="mr-1">Inoculum Size:</span>
                <span class="ml-1">{{ item.inoculum_size }}</span>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <span class="mr-1">Incubator:</span>
                <span class="ml-1">{{ item.incubator }}</span>
              </div>
              <div class="form-group col-md-6">
                <span class="mr-1">Temperature:</span>
                <span class="ml-1">{{ item.temperature }} &#8451;</span>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-3">
                <span class="mr-1">OD:</span>
                <span class="ml-1">{{ item.od }}</span>
              </div>
              <div class="form-group col-md-3">
                <span class="mr-1">PH:</span>
                <span class="ml-1">{{ item.ph }}</span>
              </div>
              <div class="form-group col-md-6">
                <span class="mr-1">Microscopic Observation:</span>
                <span class="ml-1">{{ item.micro_observation }}</span>
              </div>
            </div>
            <div class="form-group">
              <span class="mr-1">Data:</span>
              <span class="ml-1">{{ item.data }}</span>
            </div>
            <div class="form-group">
              <span class="mr-1">Note:</span>
              <span class="ml-1">{{ item.note }}</span>
            </div>
          </div>
          <!-- .modal-body -->

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancel
            </button>
          </div>
          <!-- .modal-footer -->
        </div>
        <!-- .modal-content -->
      </div>
      <!-- .modal-dialog .modal-lg-->
    </div>
  </div>
</template>

<script>
export default {
  name: "seedviewform",
  data() {
    return {
      selectedType: "seed",
      selectedTypeName: "Seed",
      item: {
        olab_id: "SD-000001",
        plate_id: "PLT-0001",
        media_id: "MD-0001",
        start_time: "01:00:00",
        end_time: "02:00:00",
        culture_time: "01:00:00",
        working_volume: "10 mL",
        flask_size: "100 mL",
        inoculum_size: "0.5 plate",
        incubator: "Incubator 1",
        temperature: 37.0,
        temperature_unit: "C",
        od: "001",
        ph: "001",
        micro_observation: "Observation info...",
        data: "TO DO",
        note: "Note section"
      }
    };
  },
  props: ["statusObj", "errorObj"],
  emits: ["emitStatusMessage", "emitErrorMessage"],
  mounted() {
    console.log("SeedViewForm mounted ...");
    // Reset status and error
    this.doEmitStatusMessage(null, false);
    this.doEmitErrorMessage(null);
  },
  methods: {
    doEmitStatusMessage(statusMsg, statusProgress) {
      this.$emit("emitStatusMessage", statusMsg, statusProgress);
    },
    doEmitErrorMessage(errMsg) {
      this.$emit("emitErrorMessage", errMsg);
    },
    closeStatus() {
      // console.log("Close Status Alert ...");
      this.emitStatusMessage(null, false);
    },
    closeError() {
      // console.log("Close Error Alert ...");
      this.emitErrorMessage(null);
    }
  }
};
</script>
