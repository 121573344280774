export const chemicals = [
  {
    olab_id: "CHM-0001",
    name: "Yeast Extract",
    qty_unit: 20,
    unit: "kg/B",
    mfr_id: "MFR-0001",
    mfr_name: "Angel"
  },
  {
    olab_id: "CHM-0002",
    name: "Corn Steep Powder",
    qty_unit: 25,
    unit: "kg/B",
    mfr_id: "MFR-0002",
    mfr_name: "ROQUETTE"
  },
  {
    olab_id: "CHM-0003",
    name: "KNO3",
    qty_unit: 25,
    unit: "kg/B",
    mfr_id: "MFR-0003",
    mfr_name: "SQM SA(Chile)"
  }
];
