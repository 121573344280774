<template>
  <div>
    <div
      v-if="errorObj.message"
      class="col-12 alert alert-danger text-center px-3"
    >
      {{ errorObj.message }}
      <div class="text-center mt-2">
        <button type="button" class="btn btn-danger" v-on:click="closeError()">
          OK
        </button>
      </div>
    </div>
    <form class="mt-3" @submit.prevent="login">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <div class="card bg-light">
              <div class="card-body">
                <h3 class="font-weight-light mb-3">Log in</h3>
                <section class="form-group">
                  <label class="form-control-label sr-only" for="Email"
                    >Email</label
                  >
                  <input
                    class="form-control"
                    v-bind:class="validEmailState()"
                    type="email"
                    id="email"
                    placeholder="Email Address"
                    required
                    v-model="email"
                  />
                  <div class="invalid-feedback">{{ invalidFB.email }}</div>
                </section>
                <section class="form-group">
                  <input
                    class="form-control"
                    v-bind:class="validPasswordState()"
                    type="password"
                    required
                    placeholder="Password"
                    v-model="password"
                  />
                  <div class="invalid-feedback">{{ invalidFB.password }}</div>
                </section>
                <div class="form-group text-right mb-0">
                  <button class="btn btn-primary" type="submit">Log in</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <p class="text-center mt-2">
      <span>
        <router-link to="/register">register</router-link>
        or
        <router-link to="/forgotpassword">forgot password?</router-link>
        or
        <a
          href="mailto:admin@olabbio.com?subject=Required Action: Forgot Password"
          >admin</a
        >
        for assistance
      </span>
    </p>
  </div>
</template>
<script>
import { auth, firebase } from "@/firebase/config.js";

export default {
  data() {
    return {
      email: "",
      password: "",
      wrongEmail: false,
      wrongPassword: false,
      invalidFB: {
        email: "User email not found.",
        password:
          "Wrong password. Try again or click forgot password to reset it."
      }
    };
  },
  props: ["statusObj", "errorObj"],
  emits: ["setStatusMessage", "setErrorMessage"],
  methods: {
    validEmailState() {
      let result = null;
      result = this.wrongEmail ? "is-invalid" : null;
      return result;
    },
    validPasswordState() {
      let result = null;
      result = this.wrongPassword ? "is-invalid" : null;
      return result;
    },
    login() {
      const info = {
        email: this.email,
        password: this.password
      };

      auth
        .signInWithEmailAndPassword(info.email, info.password)
        .then((userCredentials) => {
          console.log("user = ", userCredentials.user);
          console.log("user uid = ", userCredentials.user.uid);

          auth
            .setPersistence(firebase.auth.Auth.Persistence.SESSION)
            .then(() => {
              console.log(
                "Login: setPersistence(firebase.auth.Auth.Persistence.SESSION)"
              );
              // Existing and future Auth states are now persisted
              // in the current session only. Closing the window
              // would clear any existing state even if a user forgets
              // to sign out.
              // ...
              // New sign-in will be persisted with session persistence.
              return auth.signInWithEmailAndPassword(info.email, info.password);
            })
            .catch(
              function (error) {
                // Handle Errors here.
                const errMsg = error.code + ": " + error.message;
                this.emitErrorMessage(errMsg);
              }.bind(this)
            );

          this.$router.push("/");
        })
        .catch(
          function (error) {
            if (error.code.toLowerCase().match("wrong-password")) {
              this.wrongPassword = true;
              this.invalidFB.password =
                "Wrong password. Try again or click forgot password to reset it.";
            } else if (error.code.toLowerCase().match("too-many-requests")) {
              this.wrongPassword = true;
              this.invalidFB.password =
                "Too many unsuccessful login attempts. Try again later or click forgot password to reset it.";
            } else if (error.code.toLowerCase().match("user-not-found")) {
              this.wrongEmail = true;
              this.invalidFB.email = "User email not found.";
            } else if (error.code.toLowerCase().match("user-disabled")) {
              this.wrongEmail = true;
              this.invalidFB.email =
                "User email has been disabled. Please contact admin if this is a mistake";
            } else {
              // Handle Errors here.
              const errMsg = error.code + ": " + error.message;
              this.emitErrorMessage(errMsg);
            }
          }.bind(this)
        );
    },
    emitStatusMessage(statusMsg, statusProgress) {
      this.$emit("setStatusMessage", statusMsg, statusProgress);
    },
    emitErrorMessage(errMsg) {
      this.$emit("setErrorMessage", errMsg);
    },
    closeStatus() {
      // console.log("Close Status Alert ...");
      this.emitStatusMessage(null, false);
    },
    closeError() {
      // console.log("Close Error Alert ...");
      this.emitErrorMessage(null);
    }
  },
  watch: {
    email() {
      // console.log("email = ", this.email);
      // Reset
      if (this.wrongEmail) {
        this.wrongEmail = false;
      }
    },
    password() {
      // console.log("password = ", this.password);
      // Reset
      if (this.wrongPassword) {
        this.wrongPassword = false;
      }
    }
  }
};
</script>
