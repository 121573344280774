<template>
  <Navigation :user="user" @logout="logout" />
  <router-view
    :user="user"
    :dbInfo="dbInfo"
    :statusObj="statusObj"
    :errorObj="errorObj"
    @setStatusMessage="setStatusMessage"
    @setErrorMessage="setErrorMessage"
    @logout="logout"
  />
  <Footer></Footer>
</template>

<script>
import { auth, db } from "@/firebase/config.js";
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";
import { OlabUser } from "./olab/olabuser.js";

export default {
  name: "App",
  data() {
    return {
      dbInfo: null,
      user: null,
      statusObj: {
        message: null,
        progress: false
      },
      errorObj: {
        message: null
      }
    };
  },
  components: {
    Navigation,
    Footer
  },
  mounted() {
    let info = {};
    db.collection("db")
      .doc("info")
      .get()
      .then((snapshot) => {
        info.name = snapshot.data().name;
        info.owner = snapshot.data().owner;
        this.dbInfo = info;
      });

    auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.user = authUser;
        // Read user from the test/olab_users collection
        // cjcj users will use cjcj/olab_users collection
        // and olab users will use olab/olab_users collection
        // console.log("App: users/test/olab_users");
        db.collection("users/test/olab_users")
          .withConverter(OlabUser.getUpdateOlabUserConverter())
          .doc(authUser.email)
          .get()
          .then((snapshot) => {
            this.user = snapshot.data();
            // Note: Add authUser to user for debugging purposes
            this.user.authUser = authUser;
            // console.log("App: Sign-in provider: " + authUser.providerId);
            // OlabUser.infoLog(this.user);
            this.rejectMessage = null;
            if (this.user.status == "pending") {
              this.rejectMessage =
                "Please check back later, your account is still being process ...";
              setTimeout(() => {
                this.logout();
              }, 100);
            } else if (this.user.status != "active") {
              this.rejectMessage =
                "Please check with your admin, your account has an error";
              setTimeout(() => {
                this.logout();
              }, 100);
            }
          })
          .catch(
            function (error) {
              // Handle Errors here.
              this.error = error.code + ": " + error.message;
              console.log("authUser.email not found = " + authUser.email);
              this.rejectMessage = "Sorry, this service is for members only";
              setTimeout(() => {
                this.logout();
              }, 100);
            }.bind(this)
          );
      }
    });
  },
  methods: {
    setStatusMessage(statusMsg, statusProgress) {
      this.statusObj.message = statusMsg;
      this.statusObj.progress = statusProgress;
    },
    setErrorMessage(errMsg) {
      this.errorObj.message = errMsg;
    },
    logout() {
      auth.signOut().then(() => {
        this.user = null;
        this.$router.push("login");
      });
    }
  }
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Gugi&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");

$primary: rgb(15, 148, 231);
@import "node_modules/bootstrap/scss/bootstrap";

:root {
  /* Override Variables */
  --gray-dark: #404040;

  /* Page Variables */
  --color-text: var(--gray-dark);
  --font-family-gugi: Gugi, serif;
}

.family-gugi {
  font-family: var(--font-family-gugi);
}

.brand-color {
  background-color: #e70;
}

.text-brand-color {
  color: #e70;
}

.btn-brand {
  color: white;
  background: #e70;
}

#app {
  font-family: Inter, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-text);
  font-weight: 200;
}
</style>
