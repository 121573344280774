<template>
  <div>
    <div class="modal fade" id="viewItem">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              View:
              <span class="text-dark text-truncate">
                {{ selectedTypeName }}
              </span>
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <!-- .modal-header -->
          <div class="modal-body">
            <div class="py-3 text-wrap">
              <span class="mr-1 h5">OLAB ID:</span>
              <span class="ml-1 h5">{{ item.olab_id }}</span>
            </div>
            <div class="form-group">
              <span class="mr-1">Name:</span>
              <span class="ml-1">{{ item.name }}</span>
            </div>
            <div class="form-row">
              <div class="form-group col-md-3">
                <span class="mr-1">Quantity:</span>
                <span class="ml-1">{{ item.qty_unit }}</span>
              </div>
              <div class="form-group col-md-3">
                <span class="mr-1">Unit:</span>
                <span class="ml-1">{{ item.unit }}</span>
              </div>
              <div class="form-group col-md-6">
                <span class="mr-1">Manufacturer ID:</span>
                <span class="ml-1"
                  >{{ item.mfr_id }} - {{ item.mfr_name }}</span
                >
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <span class="mr-1">Lot Number:</span>
                <span class="ml-1">{{ item.lot_num }}</span>
              </div>
              <div class="form-group col-md-6">
                <span class="mr-1">Expiry Date:</span>
                <span class="ml-1">{{ item.exp_date }}</span>
              </div>
            </div>
            <div class="form-group">
              <span class="mr-1">Description:</span>
              <span class="ml-1">{{ item.desc }}</span>
            </div>
            <div class="form-group">
              <span class="mr-1">Note:</span>
              <span class="ml-1">{{ item.note }}</span>
            </div>
          </div>
          <!-- .modal-body -->

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancel
            </button>
          </div>
          <!-- .modal-footer -->
        </div>
        <!-- .modal-content -->
      </div>
      <!-- .modal-dialog .modal-lg-->
    </div>
  </div>
</template>

<script>
export default {
  name: "chemicalviewform",
  data() {
    return {
      selectedType: "chemical",
      selectedTypeName: "Chemical",
      item: {
        olab_id: "CHM-000001",
        name: "Yeast Extract",
        qty_unit: 20,
        unit: "kg/B",
        mfr_id: "MFR-0001",
        mfr_name: "Angel",
        lot_num: "MFR_0001",
        exp_date: "12/22/2022",
        desc: "Description section",
        note: "Note section"
      }
    };
  },
  props: ["statusObj", "errorObj"],
  emits: ["emitStatusMessage", "emitErrorMessage"],
  mounted() {
    console.log("ChemicalViewForm mounted ...");
    // Reset status and error
    this.doEmitStatusMessage(null, false);
    this.doEmitErrorMessage(null);
  },
  methods: {
    doEmitStatusMessage(statusMsg, statusProgress) {
      this.$emit("emitStatusMessage", statusMsg, statusProgress);
    },
    doEmitErrorMessage(errMsg) {
      this.$emit("emitErrorMessage", errMsg);
    },
    closeStatus() {
      // console.log("Close Status Alert ...");
      this.emitStatusMessage(null, false);
    },
    closeError() {
      // console.log("Close Error Alert ...");
      this.emitErrorMessage(null);
    }
  }
};
</script>
