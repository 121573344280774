<template>
  <div>
    <div class="modal fade" id="viewItem">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              View:
              <span class="text-dark text-truncate">
                {{ selectedTypeName }}
              </span>
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <!-- .modal-header -->
          <div class="modal-body">
            <div class="py-3 text-wrap">
              <span class="mr-1 h5">OLAB ID:</span>
              <span class="ml-1 h5">{{ item.olab_id }}</span>
            </div>
            <div class="form-group">
              <span class="mr-1">Name:</span>
              <span class="ml-1">{{ item.name }}</span>
            </div>
            <div class="form-group">
              <span class="mr-1">Address:</span>
              <span class="ml-1">{{ item.address_1 }}</span>
            </div>
            <div class="form-group">
              <span class="mr-1">Address 2:</span>
              <span class="ml-1">{{ item.address_2 }}</span>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <span class="mr-1">City:</span>
                <span class="ml-1">{{ item.city }}</span>
              </div>
              <div class="form-group col-md-4">
                <span class="mr-1">State:</span>
                <span class="ml-1">{{ item.state }}</span>
              </div>
              <div class="form-group col-md-2">
                <span class="mr-1">Zip Code:</span>
                <span class="ml-1">{{ item.zip }}</span>
              </div>
            </div>
            <div class="form-group">
              <span class="mr-1">Contact Person:</span>
              <span class="ml-1">{{ item.contact_person }}</span>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <span class="mr-1">Email:</span>
                <span class="ml-1">{{ item.email }}</span>
              </div>
              <div class="form-group col-md-6">
                <span class="mr-1">Phone:</span>
                <span class="ml-1">{{ item.phone }}</span>
              </div>
            </div>
            <div class="form-group">
              <span class="mr-1">Note:</span>
              <span class="ml-1">{{ item.note }}</span>
            </div>
          </div>
          <!-- .modal-body -->

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancel
            </button>
          </div>
          <!-- .modal-footer -->
        </div>
        <!-- .modal-content -->
      </div>
      <!-- .modal-dialog .modal-lg-->
    </div>
  </div>
</template>

<script>
export default {
  name: "manufactureviewform",
  data() {
    return {
      selectedType: "manufacture",
      selectedTypeName: "Manufacture",
      item: {
        olab_id: "MFR-0001",
        name: "Angel",
        address_1: "Address 1",
        address_2: "Address 2",
        city: "City",
        state: "State",
        zip: "Zip",
        country: "Country",
        contact_person: "Contact Person",
        email: "Email",
        phone: "Phone",
        note: "Note"
      }
    };
  },
  props: ["statusObj", "errorObj"],
  emits: ["emitStatusMessage", "emitErrorMessage"],
  mounted() {
    console.log("ManufactureViewForm mounted ...");
    // Reset status and error
    this.doEmitStatusMessage(null, false);
    this.doEmitErrorMessage(null);
  },
  methods: {
    doEmitStatusMessage(statusMsg, statusProgress) {
      this.$emit("emitStatusMessage", statusMsg, statusProgress);
    },
    doEmitErrorMessage(errMsg) {
      this.$emit("emitErrorMessage", errMsg);
    },
    closeStatus() {
      // console.log("Close Status Alert ...");
      this.emitStatusMessage(null, false);
    },
    closeError() {
      // console.log("Close Error Alert ...");
      this.emitErrorMessage(null);
    }
  }
};
</script>
