import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import Blueberry from "../views/Blueberry.vue";
import OlabChemical from "../views/blueberry/OlabChemical.vue";
import OlabFlask from "../views/blueberry/OlabFlask.vue";
import OlabIncubator from "../views/blueberry/OlabIncubator.vue";
import OlabManufacture from "../views/blueberry/OlabManufacture.vue";
import OlabMedia from "../views/blueberry/OlabMedia.vue";
import OlabPlate from "../views/blueberry/OlabPlate.vue";
import OlabRecipe from "../views/blueberry/OlabRecipe.vue";
import OlabSeed from "../views/blueberry/OlabSeed.vue";
import OlabUser from "../views/blueberry/OlabUser.vue";
import TodoPage from "../views/TodoPage.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/register",
    name: "register",
    component: Register
  },
  {
    path: "/forgotpassword",
    name: "forgotpassword",
    component: ForgotPassword
  },
  {
    path: "/todopage",
    name: "todopage",
    component: TodoPage
  },
  {
    path: "/blueberry",
    name: "blueberry",
    component: Blueberry
  },
  {
    path: "/olabchemical",
    name: "olabchemical",
    component: OlabChemical
  },
  {
    path: "/olabflask",
    name: "olabflask",
    component: OlabFlask
  },
  {
    path: "/olabincubator",
    name: "olabincubator",
    component: OlabIncubator
  },
  {
    path: "/olabrecipe",
    name: "olabrecipe",
    component: OlabRecipe
  },
  {
    path: "/olabmanufacture",
    name: "olabmanufacture",
    component: OlabManufacture
  },
  {
    path: "/olabmedia",
    name: "olabmedia",
    component: OlabMedia
  },
  {
    path: "/olabplate",
    name: "olabplate",
    component: OlabPlate
  },
  {
    path: "/olabseed",
    name: "olabseed",
    component: OlabSeed
  },
  {
    path: "/olabuser",
    name: "olabuser",
    component: OlabUser
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/"
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
