<template>
  <div>
    <div class="modal fade" id="editItem">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Edit:
              <span class="text-dark text-truncate">
                {{ selectedTypeName }}
              </span>
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <!-- .modal-header -->
          <div class="modal-body">
            <form>
              <fieldset class="form-group">
                <!-- <legend>{{selectedType}} Info</legend> -->
                <div class="py-1 text-wrap">
                  <span class="mr-1 h5">OLAB ID:</span>
                  <span class="ml-1 h5">{{ item.olab_id }}</span>
                </div>
                <div class="form-group">
                  <label class="form-control-label"> Name: </label>
                  <input
                    class="form-control"
                    v-bind:class="item.getValidState(item.name)"
                    type="text"
                    placeholder="Name"
                    v-model="item.name"
                  />
                  <!-- <div class="invalid-feedback">{{invalidFB.name}}</div> -->
                </div>
                <div class="py-1 text-wrap">
                  <span class="mr-1">Email:</span>
                  <span class="ml-1">{{ item.email }}</span>
                </div>
                <div v-if="selectedType === 'user'" class="form-group">
                  <div v-if="item.role && item.role === 'admin'">
                    <label class="form-control-label">System Role:</label>
                    <select
                      class="form-control"
                      v-bind:class="item.getValidState(item.role)"
                      v-model="item.role"
                    >
                      <option disabled value="null">Please select one</option>
                      <option value="admin">Admin</option>
                      <option value="user">User</option>
                    </select>
                    <!-- <span>selected = {{ item.role }}</span> -->
                  </div>
                  <div v-else>
                    <span class="mr-1">System Role:</span>
                    <span class="h5 text-dark">{{ item.role }}</span>
                  </div>
                </div>
              </fieldset>
              <!-- .form-group -->
            </form>
          </div>
          <!-- .modal-body -->

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancel
            </button>
          </div>
          <!-- .modal-footer -->
        </div>
        <!-- .modal-content -->
      </div>
      <!-- .modal-dialog .modal-lg-->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { OlabItem } from "@/olab/olabitem.js";

export default {
  name: "usereditform",
  data() {
    return {
      selectedType: "user",
      selectedTypeName: "User",
      item: new OlabItem({
        olab_id: "GF-000001",
        name: "Youngnyun Kim",
        email: "youngnyun.kim@olabbio.com",
        role: "admin"
      })
    };
  },
  props: ["statusObj", "errorObj"],
  emits: ["emitStatusMessage", "emitErrorMessage"],
  mounted() {
    console.log("UserEditForm mounted ...");
    // Reset status and error
    this.doEmitStatusMessage(null, false);
    this.doEmitErrorMessage(null);
  },
  methods: {
    doEmitStatusMessage(statusMsg, statusProgress) {
      this.$emit("emitStatusMessage", statusMsg, statusProgress);
    },
    doEmitErrorMessage(errMsg) {
      this.$emit("emitErrorMessage", errMsg);
    },
    closeStatus() {
      // console.log("Close Status Alert ...");
      this.emitStatusMessage(null, false);
    },
    closeError() {
      // console.log("Close Error Alert ...");
      this.emitErrorMessage(null);
    }
  }
};
</script>
