class OlabAccess {
  constructor(item) {
    if (item == null) {
      this.initAccess();
    } else {
      // "private", "group", "public"
      this.visible = item.visible ? item.visible : "public";

      // Enable display of buttons for "delete", "edit", "view"
      this.allow = item.allow ? item.allow : "view";

      // [email]: "owner", or "writer", or "viewer"
      this.roles = item.roles ? item.roles : {};

      // [groupId]: "writer", or "viewer"
      this.groups = item.groups ? item.groups : {};
    }
  }

  initAccess() {
    console.log("OlabAccess.initAccess() ...");
    this.visible = "public";
    this.allow = "view";
    this.roles = {};
    this.groups = {};
  }

  set(item) {
    console.log("OlabAccess.set() ...");
    this.visible = item.visible;
    this.allow = item.allow;
    this.roles = item.roles;
    this.groups = item.groups;
  }

  static infoLog(item) {
    console.log("OlabAccess.infoLong() ... printing ...");
    console.log("item.visible = ", item.visible);
    console.log("item.allow = ", item.allow);
    console.log("item.roles = ", item.roles);
    for (const key in item.roles) {
      // check if the property/key is defined in the object itself, not in parent
      if (Object.prototype.hasOwnProperty.call(item.roles, key)) {
        console.log("roles: ", key, " = ", item.roles[key]);
      }
    }
    console.log("item.groups = ", item.groups);
    for (const key in item.groups) {
      // check if the property/key is defined in the object itself, not in parent
      if (Object.prototype.hasOwnProperty.call(item.groups, key)) {
        console.log("groups: ", key, " = ", item.groups[key]);
      }
    }
  }
}

export { OlabAccess };
